import PropTypes from "prop-types"
import CustomPageWrapper from "components/CustomPageWrapper"
import Container from "components/Container"
import Content from "components/Content/Content"
import Gallery from "components/Gallery/Gallery"

const Article = ({ title, content, photosTitle, photos }) => (
  <CustomPageWrapper id="article">
    <Container className="container">
      <div className="content-wrapper">
        <h1 className="title">{title}</h1>
        <Content html={content.childMarkdownRemark.html} />
        <Gallery photosTitle={photosTitle} photos={photos} />
      </div>
    </Container>
  </CustomPageWrapper>
)

Article.propTypes = {
  title: PropTypes.string.isRequired,
  photosTitle: PropTypes.string.isRequired,
  content: PropTypes.exact({
    childMarkdownRemark: PropTypes.exact({
      html: PropTypes.string,
    }),
  }).isRequired,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      gatsbyImageData: PropTypes.object,
      alt: PropTypes.string,
    }),
  ).isRequired,
}

export default Article
