import styled from "@emotion/styled"

const Container = styled.div`
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin-right: auto;
    margin-left: auto;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    padding-right: 16px;
    padding-left: 16px;
  }

  ${({ theme }) => theme.breakpoints.between("sm", "md")} {
    max-width: ${({ theme }) => theme.breakpoints.values.sm}px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "lg")} {
    max-width: ${({ theme }) => theme.breakpoints.values.md}px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    max-width: ${({ theme }) => theme.breakpoints.values.lg}px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    max-width: ${({ theme }) => theme.breakpoints.values.xl}px;
    padding-right: 40px;
    padding-left: 40px;
  }
`

export default Container
