const VKIcon = (props) => (
  <svg
    {...props}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
      fill="#4D76A1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.0934 34.5262H24.9771C24.9771 34.5262 25.5462 34.4637 25.8366 34.1506C26.104 33.8631 26.0955 33.3232 26.0955 33.3232C26.0955 33.3232 26.0587 30.7956 27.2318 30.4234C28.3882 30.0567 29.8731 32.8663 31.4471 33.9465C32.6373 34.7641 33.5417 34.5848 33.5417 34.5848L37.7502 34.5262C37.7502 34.5262 39.9518 34.3906 38.9079 32.6596C38.8223 32.518 38.3 31.3791 35.7788 29.0389C33.14 26.5897 33.4934 26.9858 36.6721 22.7491C38.608 20.1689 39.3819 18.5937 39.1402 17.919C38.9096 17.2764 37.4858 17.4462 37.4858 17.4462L32.7473 17.4758C32.7473 17.4758 32.396 17.4278 32.1355 17.5836C31.8809 17.7363 31.7171 18.0922 31.7171 18.0922C31.7171 18.0922 30.9671 20.0889 29.9668 21.7869C27.8568 25.3699 27.0132 25.559 26.6683 25.3366C25.8662 24.818 26.0664 23.2535 26.0664 22.142C26.0664 18.6698 26.593 17.2221 25.0409 16.8473C24.5258 16.7228 24.1467 16.6406 22.8295 16.6274C21.1387 16.6098 19.7077 16.6325 18.8974 17.0295C18.3583 17.2935 17.9425 17.8818 18.1957 17.9156C18.5089 17.9575 19.2182 18.1068 19.5943 18.6189C20.0799 19.2795 20.0628 20.7631 20.0628 20.7631C20.0628 20.7631 20.3417 24.8506 19.4112 25.3584C18.7724 25.7066 17.8963 24.9956 16.0151 21.745C15.0512 20.0799 14.3235 18.2394 14.3235 18.2394C14.3235 18.2394 14.1832 17.8954 13.9329 17.7115C13.6292 17.4886 13.2048 17.4176 13.2048 17.4176L8.70193 17.4471C8.70193 17.4471 8.02597 17.4659 7.77783 17.7598C7.55708 18.0212 7.76029 18.562 7.76029 18.562C7.76029 18.562 11.2855 26.8096 15.2771 30.9659C18.9372 34.7765 23.0934 34.5262 23.0934 34.5262Z"
      fill="white"
    />
  </svg>
)

export default VKIcon
