import styled from "@emotion/styled"

const SliderWrapper = styled.section`
  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-bottom: 18px;
  }

  .swiper {
    ${({ theme }) => theme.breakpoints.down("lg")} {
      padding-bottom: 46px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      padding-bottom: 56px;
    }

    &-slide {
      ${({ theme }) => theme.breakpoints.up("sm")} {
        overflow: hidden;

        &, * {
          border-radius: ${({ theme }) => theme.borderRadius}px;
          overflow: hidden;
        }
      }
    }
  }

  .slider__container {
    ${({ theme }) => theme.breakpoints.down("sm")} {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
`

export default SliderWrapper
