import { useState } from "react"
import PropTypes from "prop-types"
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import TeachersWrapper from "components/Teachers/TeachersWrapper"
import Teacher from "components/Teacher/Teacher"
import TeacherModal from "components/Teacher/TeacherModal"
import scrollLock from "utils/scrollLock"
import Container from "components/Container"
import Title from "components/Title"

const Teachers = ({ title, teachers, itemLinkTitle }) => {
  const [isShowModal, setIsShowModal] = useState(false)
  const [modalData, setModalData] = useState(teachers[0])

  const handleModal = (data = null) => {
    scrollLock(!!data)
    setIsShowModal(!!data)
    if (data) setModalData(data)
  }

  return (
    <TeachersWrapper id="teachers" className="teachers">
      <Container className="teachers__container">
        <Title>{title}</Title>
        <Swiper
          modules={[Navigation]}
          centeredSlides={true}
          slidesPerView="auto"
          loop={true}
          navigation={true}
          loopedSlides={3}
        >
          {teachers.map(({ id, ...props }, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <SwiperSlide key={`${id}${i}`}>
              <Teacher
                linkTitle={itemLinkTitle}
                handleModal={() => handleModal(props)}
                {...props}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
      <TeacherModal isShow={isShowModal} closeModal={() => handleModal(null)} {...modalData} />
    </TeachersWrapper>
  )
}

Teachers.propTypes = {
  title: PropTypes.string.isRequired,
  itemLinkTitle: PropTypes.string.isRequired,
  teachers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
}

export default Teachers
