import styled from "@emotion/styled"

const SlideImageWrapper = styled.div`
  display: flex;

  .gatsby-image-wrapper {
    ${({ theme }) => theme.breakpoints.down("sm")} {
      height: 0;
      padding-bottom: 100%;
    }
  }
`

export default SlideImageWrapper
