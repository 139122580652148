import { css } from "@emotion/react"

const fontUrl = "/fonts/Inter/"

const fontInter = css`
  // @font-face {
  //   font-family: 'Inter';
  //   src: local('Inter Black'), local('Inter-Black'),
  //   url('${fontUrl}Inter-Black.woff2') format('woff2');
  //   font-weight: 900;
  //   font-style: normal;
  //   font-display: swap;
  // }
  //
  // @font-face {
  //   font-family: 'Inter';
  //   src: local('Inter ExtraLight'), local('Inter-ExtraLight'),
  //   url('${fontUrl}Inter-ExtraLight.woff2') format('woff2');
  //   font-weight: 200;
  //   font-style: normal;
  //   font-display: swap;
  // }

  @font-face {
    font-family: "Inter";
    src: local("Inter Bold"), local("Inter-Bold"), url("${fontUrl}Inter-Bold.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  // @font-face {
  //   font-family: 'Inter';
  //   src: local('Inter ExtraBold'), local('Inter-ExtraBold'),
  //   url('${fontUrl}Inter-ExtraBold.woff2') format('woff2');
  //   font-weight: bold;
  //   font-style: normal;
  //   font-display: swap;
  // }
  //
  // @font-face {
  //   font-family: 'Inter';
  //   src: local('Inter Light'), local('Inter-Light'),
  //   url('${fontUrl}Inter-Light.woff2') format('woff2');
  //   font-weight: 300;
  //   font-style: normal;
  //   font-display: swap;
  // }

  @font-face {
    font-family: "Inter";
    src: local("Inter Medium"), local("Inter-Medium"),
      url("${fontUrl}Inter-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  // @font-face {
  //   font-family: 'Inter';
  //   src: local('Inter Regular'), local('Inter-Regular'),
  //   url('${fontUrl}Inter-Regular.woff2') format('woff2');
  //   font-weight: normal;
  //   font-style: normal;
  //   font-display: swap;
  // }
  //
  // @font-face {
  //   font-family: 'Inter';
  //   src: local('Inter SemiBold'), local('Inter-SemiBold'),
  //   url('${fontUrl}Inter-SemiBold.woff2') format('woff2');
  //   font-weight: 600;
  //   font-style: normal;
  //   font-display: swap;
  // }
  //
  // @font-face {
  //   font-family: 'Inter';
  //   src: local('Inter Thin'), local('Inter-Thin'),
  //   url('${fontUrl}Inter-Thin.woff2') format('woff2');
  //   font-weight: 100;
  //   font-style: normal;
  //   font-display: swap;
  // }
`

export default fontInter
