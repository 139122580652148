import styled from "@emotion/styled"

const ApplicationFormWrapper = styled.section`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .title,
  .subtitle {
    text-align: left;
  }

  .title {
    ${({ theme }) => theme.breakpoints.down("lg")} {
      margin-bottom: 18px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      margin-bottom: 22px;
      max-width: 845px;
    }
  }

  .subtitle {
    ${({ theme }) => theme.breakpoints.down("lg")} {
      margin-bottom: 21px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      margin-bottom: 36px;
      max-width: 710px;
    }
  }

  .form-control {
    width: 100%;
  }

  .form-select {
    ${({ theme }) => theme.breakpoints.down("lg")} {
      width: 110px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      width: 150px;
    }
  }

  .btn {
    width: 100%;

    ${({ theme }) => theme.breakpoints.down("md")} {
      margin-top: 12px;
    }

    ${({ theme }) => theme.breakpoints.down("xl")} {
      padding: 15px;
      font-size: 14px;
      line-height: 20px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      position: relative;
      z-index: 1;
    }

    ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
      height: 100%;
    }

    ${({ theme }) => theme.breakpoints.up("xl")} {
      padding: 18px;
      font-size: 20px;
      line-height: 24px;
    }
  }

  .application-form {
    &__form {
      display: flex;
      flex-wrap: wrap;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        grid-row-gap: 6px;
        grid-column-gap: 6px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        grid-row-gap: 8px;
        grid-column-gap: 8px;
      }
    }

    &__label {
      ${({ theme }) => theme.breakpoints.up("md")} {
        width: 100%;
      }

      ${({ theme }) => theme.breakpoints.between("md", "lg")} {
        max-width: 208px;
      }

      ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
        max-width: 308px;
      }

      ${({ theme }) => theme.breakpoints.up("xl")} {
        max-width: 330px;
      }

      &.name {
        ${({ theme }) => theme.breakpoints.down("md")} {
          width: 100%;
        }
      }

      &.phone {
        ${({ theme }) => theme.breakpoints.down("md")} {
          max-width: calc(100% - 120px);
          width: 100%;
        }
      }

      &.age {
        ${({ theme }) => theme.breakpoints.down("lg")} {
          max-width: 110px;
        }

        ${({ theme }) => theme.breakpoints.up("lg")} {
          max-width: 150px;
        }
      }
    }

    &__btn-wrapper {
      width: 100%;
      position: relative;

      ${({ theme }) => theme.breakpoints.between("md", "lg")} {
        max-width: 192px;
      }

      ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
        max-width: 200px;
      }

      ${({ theme }) => theme.breakpoints.up("xl")} {
        max-width: 285px;
      }
    }

    &__image {
      ${({ theme }) => theme.breakpoints.down("lg")} {
        display: none;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        position: absolute;
        bottom: 0;
        right: 50%;
        transform: translateX(50%);
        object-fit: contain;
      }

      ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
        width: 167px;
        height: 166px;
      }

      ${({ theme }) => theme.breakpoints.up("xl")} {
        width: 187px;
        height: 186px;
      }
    }
  }
`

export default ApplicationFormWrapper
