import PropTypes from "prop-types"
import { Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import NewsWrapper from "components/News/NewsWrapper"
import Container from "components/Container"
import Title from "components/Title"
import NewsItem from "components/NewsItem/NewsItem"

const News = ({ baseUrl, title, news }) => (
  <NewsWrapper id="news" className="news">
    <Container className="news__container">
      <Title>{title}</Title>
      <Swiper
        modules={[Pagination]}
        slidesPerView="auto"
        spaceBetween={8}
        pagination={{ clickable: true }}
      >
        {news.map(({ id, ...props }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <SwiperSlide key={`${id}${i}`}>
            <NewsItem baseUrl={baseUrl} {...props} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  </NewsWrapper>
)

News.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  news: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
}

export default News
