import { useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

import Link from "components/Link"
import HeaderWrapper from "components/Header/HeaderWrapper"

import formatPhoneNumber from "utils/formatPhoneNumber"
import scrollLock from "utils/scrollLock"
import Button from "components/Button"
import Container from "components/Container"
import Image from "components/Image"

const Header = ({
  baseUrl,
  globalContent: { logo, phone },
  navigation,
  buttonTitle,
  buttonLink,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = (bool = null) => {
    if (bool === null) {
      scrollLock(false)
      setIsOpen(false)
    } else {
      scrollLock(!bool)
      setIsOpen(!bool)
    }
  }

  return (
    <HeaderWrapper id="header" className={clsx("header", isOpen && "open")}>
      <Container className="header__wrapper">
        <Link to={baseUrl} aria-label={logo.alt}>
          <Image className="header__logo-wrapper" image={logo} alt={logo.alt} objectFit="contain" />
        </Link>
        <div className="header__right">
          <a
            className="header__phone"
            href={`tel:${phone}`}
            aria-label={formatPhoneNumber(phone)}
            dangerouslySetInnerHTML={{ __html: formatPhoneNumber(phone, true) }}
          />
          <Button
            className={clsx("navbar-toggler", isOpen && "open")}
            type="button"
            onClick={() => handleToggle(isOpen)}
            aria-label="Menu Toggler"
          >
            <span className="navbar-toggler-icon" />
          </Button>
          <Button as={Link} variant="contained" to={buttonLink} className="header__btn-desktop">
            {buttonTitle}
          </Button>
        </div>
        <nav className={clsx("header__left", isOpen && "open")}>
          <ul className="navbar">
            {navigation.map(({ id, title, link, navSubItems = [] }) => (
              <li key={id} className="nav-item">
                <Link
                  to={link}
                  onClick={handleToggle}
                  className={clsx("nav-link", navSubItems?.length && "arrow")}
                >
                  {title}
                </Link>

                {!!navSubItems?.length && (
                  <ul className="dropdown-menu">
                    {navSubItems.map(({ id: idItem, title: titleItem, link: linkItem }) => (
                      <li key={idItem}>
                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                        <Link to={linkItem} onClick={handleToggle} className="dropdown-link">
                          {titleItem}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>

          <div>
            <Button as={Link} variant="contained" to={buttonLink} className="header__btn-mobile">
              {buttonTitle}
            </Button>
          </div>
        </nav>
      </Container>
    </HeaderWrapper>
  )
}

Header.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  globalContent: PropTypes.exact({
    logo: PropTypes.exact({
      gatsbyImageData: PropTypes.object,
      alt: PropTypes.string,
    }),
    phone: PropTypes.string,
  }).isRequired,
  navigation: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
      navSubItems: PropTypes.arrayOf(
        PropTypes.exact({
          id: PropTypes.string,
          title: PropTypes.string,
          link: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  buttonTitle: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
}

export default Header
