import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/react"
import clsx from "clsx"
import Link from "components/Link"
import CourseItemWrapper from "components/CourseItem/CourseItemWrapper"
import Image from "components/Image"

const CourseItem = ({
  baseUrl,
  text,
  titlePrice,
  price,
  priceColor,
  titleDate,
  date,
  borderColor,
  course: { banners, title, subtitle, slug },
}) => (
  <CourseItemWrapper
    as={Link}
    to={`${baseUrl}courses/${slug}`}
    className={clsx(
      "course-item",
      (!!price || !!titlePrice || !!titleDate || !!date) && "course-item-with-price",
    )}
    css={css`
      border: 1px solid ${borderColor};
    `}
  >
    <div>
      <div className="course-item__images">
        {banners.map(({ id, alt, ...image }) => (
          <Image
            key={id}
            className="course-item__image"
            image={image}
            alt={alt}
            objectFit="contain"
          />
        ))}
      </div>
      <h2 className="course-item__title">{title}</h2>
      <p className="course-item__subtitle">{subtitle.text}</p>
      {!!text?.text && <p className="course-item__text">{text.text}</p>}
    </div>
    <div>
      <div className="course-item__bottom">
        <div>
          {!!titleDate && <div className="course-item__title-date">{titleDate}</div>}
          {!!date && <div className="course-item__date">{date}</div>}
        </div>
        <div>
          {!!titlePrice && <div className="course-item__title-price">{titlePrice}</div>}
          {!!price && (
            <div className="course-item__price" style={{ color: priceColor }}>
              {price}
            </div>
          )}
        </div>
      </div>
    </div>
  </CourseItemWrapper>
)

CourseItem.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  text: PropTypes.shape({
    text: PropTypes.string,
  }),
  titlePrice: PropTypes.string,
  price: PropTypes.string,
  titleDate: PropTypes.string,
  date: PropTypes.string,
  borderColor: PropTypes.string.isRequired,
  priceColor: PropTypes.string.isRequired,
  course: PropTypes.shape({
    banners: PropTypes.arrayOf(
      PropTypes.shape({
        gatsbyImageData: PropTypes.object,
        alt: PropTypes.string,
      }),
    ),
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.exact({
      text: PropTypes.string,
    }).isRequired,
    slug: PropTypes.string,
  }).isRequired,
}

CourseItem.defaultProps = {
  titlePrice: "",
  price: "",
  titleDate: "",
  date: "",
  text: {
    text: "",
  },
}

export default CourseItem
