import styled from "@emotion/styled"

const Subtitle = styled.p`
  text-align: center;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 6px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 12px;
  }
`

export default Subtitle
