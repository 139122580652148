import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"
import Link from "components/Link"
import InstagramWrapper from "components/Instagram/InstagramWrapper"
import Container from "components/Container"
import Title from "components/Title"
import LazyImage from "components/LazyImage"

const Instagram = ({ title }) => {
  const [feeds, setFeeds] = useState([
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
  ])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (process.env.GATSBY_INSTAGRAM_ACCOUNT_ID && process.env.GATSBY_INSTAGRAM_ACCESS_TOKEN) {
      setLoading(true)
      fetch(
        `https://graph.facebook.com/v7.0/${process.env.GATSBY_INSTAGRAM_ACCOUNT_ID}/media?fields=media_url,thumbnail_url,caption,media_type,shortcode,timestamp,children{media_url},permalink&limit=10&access_token=${process.env.GATSBY_INSTAGRAM_ACCESS_TOKEN}`,
      )
        .then((res) => res.json())
        .then((json) => {
          setLoading(false)
          setFeeds(
            json.data.map((post, index) => {
              return {
                id: post?.shortcode || index,
                alt: post?.caption || "",
                src: post.thumbnail_url || post?.media_url || "",
                timestamp: new Date(post.timestamp).getTime() / 1000,
              }
            }),
          )
        })
        .catch((err) => {
          setError(true)
          setLoading(false)
          // eslint-disable-next-line no-console
          console.log(err)
        })
    }
    if (!process.env.GATSBY_INSTAGRAM_ACCOUNT_ID && !process.env.GATSBY_INSTAGRAM_ACCESS_TOKEN)
      setError(true)
  }, [])

  if (!loading && error) return null
  return (
    <InstagramWrapper id="instagram" className="instagram">
      <Container className="instagram__container">
        <Title>{title}</Title>
        <Swiper slidesPerView="auto" spaceBetween={8}>
          {/* eslint-disable-next-line no-unused-vars */}
          {feeds.map(
            ({
              id,
              src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=",
              alt = "image",
            }) => (
              <SwiperSlide key={id}>
                <Link to={`https://www.instagram.com/p/${id}`} className="instagram-item">
                  <LazyImage src={src} alt={alt} />
                </Link>
              </SwiperSlide>
            ),
          )}
        </Swiper>
      </Container>
    </InstagramWrapper>
  )
}

Instagram.propTypes = {
  title: PropTypes.string.isRequired,
}

export default Instagram
