import { css } from "@emotion/react"

const fontUrl = "/fonts/Montserrat/"

const fontMontserrat = css`
  // @font-face {
  //     font-family: 'Montserrat';
  //     src: local('Montserrat Black'), local('Montserrat-Black'),
  //         url('${fontUrl}Montserrat-Black.woff2') format('woff2');
  //     font-weight: 900;
  //     font-style: normal;
  //     font-display: swap;
  // }
  //
  // @font-face {
  //     font-family: 'Montserrat';
  //     src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
  //         url('${fontUrl}Montserrat-ExtraLightItalic.woff2') format('woff2');
  //     font-weight: 200;
  //     font-style: italic;
  //     font-display: swap;
  // }

  @font-face {
    font-family: "Montserrat";
    src: local("Montserrat Italic"), local("Montserrat-Italic"),
      url("${fontUrl}Montserrat-Italic.woff2") format("woff2");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Montserrat";
    src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"),
      url("${fontUrl}Montserrat-ExtraBold.woff2") format("woff2");
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    font-style: normal;
    font-display: swap;
  }

  // @font-face {
  //     font-family: 'Montserrat';
  //     src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'),
  //         url('${fontUrl}Montserrat-BlackItalic.woff2') format('woff2');
  //     font-weight: 900;
  //     font-style: italic;
  //     font-display: swap;
  // }
  //
  // @font-face {
  //     font-family: 'Montserrat';
  //     src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'),
  //         url('${fontUrl}Montserrat-ExtraBoldItalic.woff2') format('woff2');
  //     font-weight: bold;
  //     font-style: italic;
  //     font-display: swap;
  // }

  @font-face {
    font-family: "Montserrat";
    src: local("Montserrat Bold"), local("Montserrat-Bold"),
      url("${fontUrl}Montserrat-Bold.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  // @font-face {
  //     font-family: 'Montserrat';
  //     src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
  //         url('${fontUrl}Montserrat-ExtraLight.woff2') format('woff2');
  //     font-weight: 200;
  //     font-style: normal;
  //     font-display: swap;
  // }
  //
  // @font-face {
  //     font-family: 'Montserrat';
  //     src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
  //         url('${fontUrl}Montserrat-BoldItalic.woff2') format('woff2');
  //     font-weight: bold;
  //     font-style: italic;
  //     font-display: swap;
  // }

  @font-face {
    font-family: "Montserrat";
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
      url("${fontUrl}Montserrat-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  // @font-face {
  //     font-family: 'Montserrat';
  //     src: local('Montserrat Light'), local('Montserrat-Light'),
  //         url('${fontUrl}Montserrat-Light.woff2') format('woff2');
  //     font-weight: 300;
  //     font-style: normal;
  //     font-display: swap;
  // }

  @font-face {
    font-family: "Montserrat";
    src: local("Montserrat Medium"), local("Montserrat-Medium"),
      url("${fontUrl}Montserrat-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  // @font-face {
  //     font-family: 'Montserrat';
  //     src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
  //         url('${fontUrl}Montserrat-LightItalic.woff2') format('woff2');
  //     font-weight: 300;
  //     font-style: italic;
  //     font-display: swap;
  // }
  //
  // @font-face {
  //     font-family: 'Montserrat';
  //     src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
  //         url('${fontUrl}Montserrat-MediumItalic.woff2') format('woff2');
  //     font-weight: 500;
  //     font-style: italic;
  //     font-display: swap;
  // }

  @font-face {
    font-family: "Montserrat";
    src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
      url("${fontUrl}Montserrat-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  // @font-face {
  //     font-family: 'Montserrat';
  //     src: local('Montserrat Thin'), local('Montserrat-Thin'),
  //         url('${fontUrl}Montserrat-Thin.woff2') format('woff2');
  //     font-weight: 100;
  //     font-style: normal;
  //     font-display: swap;
  // }
  //
  // @font-face {
  //     font-family: 'Montserrat';
  //     src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
  //         url('${fontUrl}Montserrat-SemiBoldItalic.woff2') format('woff2');
  //     font-weight: 600;
  //     font-style: italic;
  //     font-display: swap;
  // }
  //
  // @font-face {
  //     font-family: 'Montserrat';
  //     src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'),
  //         url('${fontUrl}Montserrat-ThinItalic.woff2') format('woff2');
  //     font-weight: 100;
  //     font-style: italic;
  //     font-display: swap;
  // }
`

export default fontMontserrat
