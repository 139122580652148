import styled from "@emotion/styled"

const NewsItemWrapper = styled.div`
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  position: relative;

  .news-item {
    &__image-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;

      &,
      * {
        border-radius: ${({ theme }) => theme.borderRadius}px;
        overflow: hidden;
      }
    }

    &__content {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: inline-block;
        background: linear-gradient(180deg, rgba(0, 36, 110, 0) 0%, #1f104a 100%);
        border-radius: ${({ theme }) => theme.borderRadius}px;
        overflow: hidden;
        z-index: -1;
      }

      ${({ theme }) => theme.breakpoints.down("lg")} {
        padding: 16px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        padding: 24px;
      }
    }

    &__title {
      font-weight: 700;
      color: ${({ theme }) => theme.palette.common.white};
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        font-size: 20px;
        line-height: 24px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        font-size: 24px;
        line-height: 30px;
      }
    }

    &__short-desc {
      line-height: 20px;
      color: ${({ theme }) => theme.palette.common.white};
      margin-bottom: 0;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        font-size: 12px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        font-size: 14px;
      }
    }
  }
`

export default NewsItemWrapper
