import styled from "@emotion/styled"
import FlagIcon from "components/icons/FlagIcon"
import CupIcon from "components/icons/CupIcon"

const TestLevelPaginationWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 22px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-bottom: 86px;
  }

  .numbers {
    position: relative;
    display: flex;

    &:not(.one) {
      justify-content: space-between;
    }

    &.one {
      justify-content: center;
    }

    &:not(.one)::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      height: 4px;
      width: 100%;
      background-color: #e5ebf4;
      z-index: 0;
    }
  }

  .number {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 500;

    &:not(.active) {
      background-color: #e5ebf4;
      color: #72767a;
    }

    &.active {
      background-color: #017ce3;
      color: #fff;
    }

    ${({ theme }) => theme.breakpoints.down("lg")} {
      width: 20px;
      height: 20px;
      font-size: 12px;
      line-height: 16px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      width: 40px;
      height: 40px;
      font-size: 24px;
      line-height: 26px;
    }
  }

  .icon-wrapper {
    display: flex;
    justify-content: space-between;

    ${({ theme }) => theme.breakpoints.down("lg")} {
      margin-bottom: 6px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      margin-bottom: 14px;
    }
  }

  .flag-icon {
    ${({ theme }) => theme.breakpoints.down("lg")} {
      width: 18px;
      height: 18px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      width: 47px;
      height: 47px;
    }
  }

  .cup-icon {
    ${({ theme }) => theme.breakpoints.down("lg")} {
      width: 18px;
      height: 18px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      width: 47px;
      height: 47px;
    }
  }
`

// eslint-disable-next-line react/prop-types
const TestLevelPagination = ({ tasks, active }) => {
  return (
    <TestLevelPaginationWrapper>
      <div className="icon-wrapper">
        <FlagIcon className="flag-icon" />
        <CupIcon className="cup-icon" />
      </div>
      {/* eslint-disable-next-line react/prop-types */}
      <div className={`numbers${tasks?.length === 1 ? " one" : ""}`}>
        {/* eslint-disable-next-line react/prop-types */}
        {tasks.map(({ id }, index) => (
          <div key={id} className={`number${active === index ? " active" : ""}`}>
            {index + 1}
          </div>
        ))}
      </div>
    </TestLevelPaginationWrapper>
  )
}

export default TestLevelPagination
