import PropTypes from "prop-types"
import Link from "components/Link"
import NewsItemWrapper from "components/NewsItem/NewsItemWrapper"
import Image from "components/Image"

const NewsItem = ({ baseUrl, slug, title, excerpt, photos }) => (
  <NewsItemWrapper as={Link} className="news-item" to={`${baseUrl}news/${slug}`}>
    <div className="news-item__content">
      <h2 className="news-item__title">{title}</h2>
      <p className="news-item__short-desc">{excerpt?.text}</p>
    </div>
    <Image
      className="news-item__image-wrapper"
      image={photos[0]}
      alt={photos[0]?.alt}
      objectFit="cover"
    />
  </NewsItemWrapper>
)

NewsItem.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      gatsbyImageData: PropTypes.object,
      alt: PropTypes.string,
    }),
  ).isRequired,
  excerpt: PropTypes.exact({
    text: PropTypes.string,
  }).isRequired,
}

export default NewsItem
