import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ error = false, theme }) => css`
  background-color: ${error ? theme.palette.error.light : theme.palette.gray["100"]};
`

const FormControl = styled.input`
  //display: block;
  //width: 100%;
  font-family: inherit;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.common.black};
  background-clip: padding-box;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius / 2.5}px;
  appearance: none;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding: 15px;
    font-size: 14px;
    line-height: 18px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding: 18px;
    font-size: 20px;
    line-height: 24px;
  }

  &[type="file"] {
    overflow: hidden;

    &:not(:disabled):not([readonly]) {
      cursor: pointer;
    }
  }

  &:focus {
    color: ${({ theme }) => theme.palette.common.black};
    border-color: transparent;
    outline: 0;
  }

  &::placeholder {
    color: ${({ theme }) => theme.palette.gray["400"]};
    opacity: 1;
  }

  &:disabled,
  &[readonly] {
    color: ${({ theme }) => theme.palette.gray["300"]};
    opacity: 1;
  }

  &::file-selector-button {
    color: ${({ theme }) => theme.palette.common.black};
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
  }

  &::-webkit-file-upload-button {
    color: ${({ theme }) => theme.palette.common.black};
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
  }

  ${dynamicStyle};
`

export default FormControl
