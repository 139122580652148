import styled from "@emotion/styled"

const TestimonialsWrapper = styled.section`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .swiper {
    ${({ theme }) => theme.breakpoints.down("sm")} {
      padding-left: 16px;
      padding-right: 16px;
    }

    ${({ theme }) => theme.breakpoints.down("lg")} {
      padding-top: 29px;
      padding-bottom: 46px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      padding-top: 35px;
      padding-bottom: 56px;
    }

    ${({ theme }) => theme.breakpoints.up("xl")} {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  .swiper-slide {
    ${({ theme }) => theme.breakpoints.down("md")} {
      width: 100%;
    }

    ${({ theme }) => theme.breakpoints.between("md", "lg")} {
      width: calc(50% - 30px);
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      width: calc(33.33% - 20px);
    }

    &:nth-of-type(odd) {
      .testimonial-item__image-wrapper {
        border: 2px solid #1b4f92;

        &::before {
          left: 49px;
          border-top: 2px solid #de2a60;
          border-left: 2px solid #de2a60;
          transform: rotate(66deg);
        }

        &::after {
          left: 48px;
          border-top: 2px solid #de2a60;
          border-left: 2px solid #de2a60;
          transform: rotate(66deg);
        }
      }
    }

    &:nth-of-type(even) {
      .testimonial-item__image-wrapper {
        border: 2px solid #de2a60;

        &::before {
          left: 9px;
          border-top: 2px solid #1b4f92;
          border-left: 2px solid #1b4f92;
          transform: rotate(33deg);
        }

        &::after {
          left: 14px;
          border-top: 2px solid #1b4f92;
          border-left: 2px solid #1b4f92;
          transform: rotate(33deg);
        }
      }
    }
  }

  .swiper-pagination-bullet {
    border: 5px solid ${({ theme }) => theme.palette.common.white};
  }

  .testimonials__container {
    ${({ theme }) => theme.breakpoints.down("sm")} {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
`

export default TestimonialsWrapper
