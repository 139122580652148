import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Cookies from "js-cookie"
import AnnouncementBarWrapper from "components/AnnouncementBar/AnnouncementBarWrapper"
import Container from "components/Container"
import Link from "components/Link"
import Image from "components/Image"

const KEY = "light-english-announcement"

const AnnouncementBar = ({ image, text, link }) => {
  const [isShow, setIsShow] = useState(false)

  useEffect(() => {
    const cookie = Cookies.get(KEY)
    if (!cookie) setIsShow(true)
  }, [])

  const handleClick = () => {
    setIsShow(false)
    Cookies.set(KEY, KEY, { expires: 1 })
  }

  if (!isShow) return null
  return (
    <AnnouncementBarWrapper id="announcement-bar" className="announcement-bar">
      <Container className="announcement-bar__container">
        <Link className="announcement-bar__link" to={link}>
          <div className="announcement-bar__image-wrapper">
            <Image image={image} alt={image.alt} objectFit="cover" />
          </div>
          <div
            className="announcement-bar__text"
            dangerouslySetInnerHTML={{
              __html: text.childMarkdownRemark.html,
            }}
          />
        </Link>
      </Container>
      <button
        type="button"
        aria-label="close"
        className="announcement-bar__btn-close"
        onClick={handleClick}
      />
    </AnnouncementBarWrapper>
  )
}

AnnouncementBar.propTypes = {
  image: PropTypes.exact({
    gatsbyImageData: PropTypes.object,
    alt: PropTypes.string,
  }).isRequired,
  text: PropTypes.exact({
    childMarkdownRemark: PropTypes.exact({
      html: PropTypes.string,
    }),
  }).isRequired,
  link: PropTypes.string.isRequired,
}

export default AnnouncementBar
