const MessageIcon = (props) => (
  <svg
    {...props}
    width="230"
    height="206"
    viewBox="0 0 230 206"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 204.333L17.4667 154.933C3.23497 133.885 -1.91342 108.958 2.97877 84.7889C7.87095 60.6194 22.4708 38.8515 44.0637 23.5325C65.6566 8.21346 92.7733 0.385738 120.372 1.50474C147.97 2.62375 174.173 12.6133 194.107 29.6161C214.041 46.6189 226.351 69.4779 228.747 93.9428C231.144 118.408 223.464 142.814 207.136 162.623C190.807 182.433 166.942 196.297 139.976 201.64C113.01 206.982 84.7799 203.438 60.5333 191.666L1 204.333"
      stroke="#C7CBCF"
    />
  </svg>
)

export default MessageIcon
