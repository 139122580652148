import styled from "@emotion/styled"

const TestsWrapper = styled.section`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .tests__list {
    ${({ theme }) => theme.breakpoints.up("md")} {
      display: grid;
      grid-row-gap: 25px;
      grid-column-gap: 25px;
      grid-template-columns: calc(33.33% - 16.67px) calc(33.33% - 16.67px) calc(33.33% - 16.67px);
    }
  }
`

export default TestsWrapper
