import styled from "@emotion/styled"

const TestWrapper = styled.main`
  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-left: 16px;
    margin-right: 16px;
  }

  .content-wrapper {
    background-color: ${({ theme }) => theme.palette.common.white};

    ${({ theme }) => theme.breakpoints.down("lg")} {
      padding-top: 48px;
      padding-bottom: 48px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      border-radius: ${({ theme }) => theme.borderRadius}px;
      box-shadow: 0 2px 72px rgba(0, 0, 0, 0.05);
      padding: 48px;
    }
  }

  .title {
    font-weight: 600;
    line-height: 1.14;
    margin-bottom: 1.5rem;

    ${({ theme }) => theme.breakpoints.down("lg")} {
      font-size: 26px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      font-size: 44px;
    }
  }

  .test {
    &__task-caption {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;
      color: #677083;
    }

    &__task-header {
      margin-bottom: 30px;
    }

    &__test-answer {
      text-align: left;
      background-color: transparent;
      border: none;
      cursor: pointer;
      padding-left: 32px;
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      position: relative;

      &::before {
        content: "";
        width: 24px;
        height: 24px;
        background: #e4eaef;
        border-radius: 50%;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
      }

      &.active::after {
        content: "";
        width: 14px;
        height: 14px;
        background: #017ce3;
        border-radius: 50%;
        display: block;
        position: absolute;
        left: 5px;
        top: 5px;
      }
    }

    &__button {
      margin-top: 48px;
      border-radius: 8px;
      font-weight: 600;
      padding: 12px 16px;

      &.next {
        display: flex;
        align-items: center;

        &:disabled {
          opacity: 0.5;
          pointer-events: none;
          cursor: not-allowed;
        }

        &::after {
          content: "";
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTk5ODQgMC42NjY5OTJMOC4zNTQ4NCAyLjMxMTk5TDE0Ljg2NDggOC44MzM2NkgwLjY2NjUwNFYxMS4xNjdIMTQuODY0OEw4LjM1NDg0IDE3LjY4ODdMOS45OTk4NCAxOS4zMzM3TDE5LjMzMzIgMTAuMDAwM0w5Ljk5OTg0IDAuNjY2OTkyWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");
          background-size: cover;
          display: inline-block;
          margin-left: 10px;

          ${({ theme }) => theme.breakpoints.down("lg")} {
            width: 12px;
            height: 12px;
          }

          ${({ theme }) => theme.breakpoints.up("lg")} {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    &__result-main {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    &__result-icon {
      margin-left: auto;
      margin-right: auto;
      display: block;
      width: 76px;
      height: 76px;
      margin-bottom: 16px;
    }

    &__result-content-wrap,
    &__result-link-wrapper {
      text-align: center;
    }

    &__result-link {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-transform: uppercase;
      color: #017ce3;
      display: flex;
      align-items: center;
      justify-content: center;

      &::after {
        content: "";
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjU4ODQgMy45MjA3N0MxMC4yNjM4IDMuNTk2MTYgOS43Mzc1IDMuNTk2MTYgOS40MTI4OSAzLjkyMDc3QzkuMDg4NDggNC4yNDUxOCA5LjA4ODI0IDQuNzcxMDcgOS40MTIzNiA1LjA5NTc3TDEzLjQ3NTYgOS4xNjYzNEg0LjE2NzMyQzMuNzA3MDggOS4xNjYzNCAzLjMzMzk4IDkuNTM5NDQgMy4zMzM5OCA5Ljk5OTY3QzMuMzMzOTggMTAuNDU5OSAzLjcwNzA4IDEwLjgzMyA0LjE2NzMyIDEwLjgzM0gxMy40NzU2TDkuNDEyMzYgMTQuOTAzNkM5LjA4ODI0IDE1LjIyODMgOS4wODg0OCAxNS43NTQyIDkuNDEyODkgMTYuMDc4NkM5LjczNzUgMTYuNDAzMiAxMC4yNjM4IDE2LjQwMzIgMTAuNTg4NCAxNi4wNzg2TDE1Ljk2MDIgMTAuNzA2OEMxNi4zNTA3IDEwLjMxNjMgMTYuMzUwNyA5LjY4MzA5IDE1Ljk2MDIgOS4yOTI1N0wxMC41ODg0IDMuOTIwNzdaIiBmaWxsPSIjMDE3Q0UzIi8+Cjwvc3ZnPgo=");
        width: 20px;
        height: 20px;
        background-size: cover;
        display: inline-block;
        margin-left: 2px;
      }
    }
  }
`

export default TestWrapper
