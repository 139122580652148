import styled from "@emotion/styled"

const CoursesWrapper = styled.section`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .courses {
    &__list {
      display: grid;
      grid-row-gap: 25px;
      grid-column-gap: 25px;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        padding-top: 10px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        padding-top: 14px;
      }

      ${({ theme }) => theme.breakpoints.up("md")} {
        grid-template-columns: calc(50% - 12.5px) calc(50% - 12.5px);
      }
    }
  }
`

export default CoursesWrapper
