const CupIcon = (props) => {
  return (
    <svg
      {...props}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.352 15.9316L9.5 14.8184L5.64801 15.9316C5.61465 15.9984 5.60352 16.0652 5.60352 16.1432V18.4439H13.3965V16.1432C13.3965 16.0652 13.3854 15.9984 13.352 15.9316Z"
        fill="#646D73"
      />
      <path
        d="M13.3965 18.4439V16.1432C13.3965 16.0652 13.3854 15.9984 13.352 15.9316L9.5 14.8184V18.4439H13.3965Z"
        fill="#474F54"
      />
      <path
        d="M18.4434 1.11328H15.5991C15.604 0.925248 15.623 0.748125 15.623 0.556641C15.623 0.244885 15.3782 0 15.0664 0H3.93359C3.62184 0 3.37695 0.244885 3.37695 0.556641C3.37695 0.748125 3.39599 0.925248 3.40081 1.11328H0.556641C0.248967 1.11328 0 1.36225 0 1.66992V3.08C0 6.18806 2.48974 8.71287 5.55798 8.87541C6.19719 9.7555 6.95582 10.4086 7.81895 10.7766C7.68535 13.4818 6.13789 15.3633 5.77055 15.753C5.71484 15.7975 5.67031 15.8643 5.64805 15.9311H13.352C13.3297 15.8643 13.2852 15.7975 13.2295 15.753C12.851 15.3633 11.3146 13.493 11.1811 10.7766C12.0444 10.4085 12.8076 9.75527 13.4473 8.87486C16.5129 8.70968 19 6.18632 19 3.08V1.66992C19 1.36225 18.751 1.11328 18.4434 1.11328ZM1.11328 3.08V2.22656H3.44857C3.59304 4.06637 3.992 6.01491 4.83053 7.6708C2.72865 7.19747 1.11328 5.32308 1.11328 3.08ZM17.8867 3.08C17.8867 5.32193 16.2728 7.19543 14.1725 7.66984C15.0044 6.01398 15.4055 4.08429 15.5514 2.22656H17.8867V3.08Z"
        fill="#FED843"
      />
      <path
        d="M18.4434 1.11328H15.5991C15.604 0.925248 15.623 0.748125 15.623 0.556641C15.623 0.244885 15.3782 0 15.0664 0H9.5V15.9311H13.352C13.3297 15.8643 13.2852 15.7975 13.2295 15.753C12.851 15.3633 11.3146 13.493 11.1811 10.7766C12.0444 10.4085 12.8076 9.75527 13.4473 8.87486C16.5129 8.70968 19 6.18632 19 3.08V1.66992C19 1.36225 18.751 1.11328 18.4434 1.11328ZM17.8867 3.08C17.8867 5.32193 16.2728 7.19543 14.1725 7.66984C15.0044 6.01398 15.4056 4.08429 15.5514 2.22656H17.8867V3.08Z"
        fill="#FABE2C"
      />
      <path
        d="M10.3883 7.08L9.50001 6.61847L8.61176 7.08C8.42585 7.17567 8.19862 7.1599 8.02796 7.03651C7.85726 6.91256 7.77138 6.70275 7.80616 6.49508L7.96925 5.50627L7.25716 4.80341C7.10215 4.6523 7.05477 4.42828 7.118 4.23482C7.18324 4.03477 7.35609 3.888 7.56483 3.85649L8.55417 3.70754L9.00208 2.81224C9.19126 2.43498 9.80876 2.43498 9.99795 2.81224L10.4459 3.70754L11.4352 3.85649C11.6439 3.88804 11.8168 4.03481 11.882 4.23482C11.9473 4.4354 11.894 4.65557 11.7429 4.80341L11.0308 5.50627L11.1939 6.49508C11.2287 6.70275 11.1428 6.91256 10.9721 7.03651C10.8025 7.15938 10.5757 7.17752 10.3883 7.08Z"
        fill="#FABE2C"
      />
      <path
        d="M10.3883 7.081C10.5757 7.17852 10.8025 7.16037 10.9721 7.03751C11.1428 6.91356 11.2286 6.70374 11.1939 6.49608L11.0308 5.50726L11.7429 4.80441C11.894 4.65657 11.9473 4.4364 11.882 4.23582C11.8168 4.03576 11.6439 3.889 11.4352 3.85749L10.4458 3.70853L9.99793 2.81323C9.90334 2.62461 9.70169 2.53027 9.5 2.53027V6.61947L10.3883 7.081Z"
        fill="#FF9100"
      />
      <path
        d="M13.9531 19H5.04688C4.7392 19 4.49023 18.751 4.49023 18.4434C4.49023 18.1357 4.7392 17.8867 5.04688 17.8867H13.9531C14.2608 17.8867 14.5098 18.1357 14.5098 18.4434C14.5098 18.751 14.2608 19 13.9531 19Z"
        fill="#474F54"
      />
      <path
        d="M13.9531 17.8867H9.5V19H13.9531C14.2608 19 14.5098 18.751 14.5098 18.4434C14.5098 18.1357 14.2608 17.8867 13.9531 17.8867Z"
        fill="#32393F"
      />
    </svg>
  )
}

export default CupIcon
