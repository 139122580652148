import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ variant = "text", theme }) => css`
  color: ${variant === "contained" ? theme.palette.common.white : theme.palette.common.black};
  background-color: ${variant === "contained" ? theme.palette.primary.main : "transparent"};
`

const Button = styled.button`
  display: inline-block;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  border: 0 solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: ${({ theme }) => theme.typography.fontSize};
  border-radius: ${({ theme }) => theme.borderRadius / 2}px;
  text-transform: uppercase;
  line-height: 1.5;
  ${dynamicStyle};
`

export default Button
