import PropTypes from "prop-types"
import { Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import TestimonialsWrapper from "components/Testimonials/TestimonialsWrapper"
import Container from "components/Container"
import Title from "components/Title"
import Subtitle from "components/Subtitle"
import Testimonial from "components/Testimonial/Testimonial"

const Testimonials = ({ title, subtitle, testimonials }) => (
  <TestimonialsWrapper id="testimonials" className="testimonials">
    <Container className="testimonials__container">
      <Title>{title}</Title>
      {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
      <Swiper
        modules={[Pagination]}
        slidesPerView="auto"
        spaceBetween={30}
        pagination={{ clickable: true }}
      >
        {testimonials.map(({ id, ...props }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <SwiperSlide key={`${id}${i}`}>
            <Testimonial {...props} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  </TestimonialsWrapper>
)

Testimonials.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
}

Testimonials.defaultProps = {
  subtitle: "",
}

export default Testimonials
