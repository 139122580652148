import PropTypes from "prop-types"
import CustomPageWrapper from "components/CustomPageWrapper"
import Container from "components/Container"
import MyContent from "components/Content/Content"

const Content = ({ content }) => {
  if (!content?.childMarkdownRemark?.html) return null
  return (
    <CustomPageWrapper id="section-content">
      <Container>
        <div className="content-wrapper">
          <MyContent html={content.childMarkdownRemark.html} />
        </div>
      </Container>
    </CustomPageWrapper>
  )
}

Content.propTypes = {
  content: PropTypes.exact({
    childMarkdownRemark: PropTypes.exact({
      html: PropTypes.string,
    }),
  }).isRequired,
}

export default Content
