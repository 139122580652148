import styled from "@emotion/styled"

const GalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  .gallery__title {
    font-weight: 600;
    line-height: 1;

    ${({ theme }) => theme.breakpoints.down("lg")} {
      font-size: 18px;
      margin-top: 30px;
      margin-bottom: 16px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      font-size: 24px;
      margin-top: 48px;
      margin-bottom: 22px;
    }
  }

  .gallery__photo {
    &-list {
      display: flex;
      flex-wrap: wrap;
    }

    &-item {
      flex-grow: 1;
      margin-right: 1em;
      margin-bottom: 1em;
      background-color: #f0f0f0;

      ${({ theme }) => theme.breakpoints.down("md")} {
        width: calc(50% - 1em);

        &:nth-of-type(2n) {
          margin-right: 0;
        }
      }

      ${({ theme }) => theme.breakpoints.up("md")} {
        width: calc(33.33333% - 0.66667em);

        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &,
      * {
        overflow: hidden;
        border-radius: ${({ theme }) => theme.borderRadius}px;
      }
    }
  }
`

export default GalleryWrapper
