import PropTypes from "prop-types"
import InputMask from "react-input-mask"
import clsx from "clsx"
import { useFormik } from "formik"
import * as yup from "yup"
import NetlifyForm from "components/NetlifyForm"
import ApplicationFormWrapper from "components/ApplicationForm/ApplicationFormWrapper"
import Button from "components/Button"
import FormControl from "components/FormControl"
import FormSelect from "components/FormSelect"
import Title from "components/Title"
import Subtitle from "components/Subtitle"
import Container from "components/Container"

const validationSchema = yup.object({
  name: yup.string().required(),
  age: yup.string().required(),
  phone: yup
    .string()
    .matches(/^\+375 \d{2} \d{3}-\d{2}-\d{2}/)
    .required(),
})

const ApplicationForm = ({
  title,
  subtitle,
  btnTitle,
  image,
  fieldNamePlaceholder,
  fieldAgePlaceholder,
  fieldAgeOptions,
  fieldPhonePlaceholder,
  fieldPhoneMask,
}) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      age: "",
      phone: "",
    },
    validationSchema,
    onSubmit: () => {},
  })

  return (
    <ApplicationFormWrapper id="application-form" className="application-form">
      <Container>
        <Title className="title">{title}</Title>
        <Subtitle className="subtitle">{subtitle}</Subtitle>
        <NetlifyForm
          className="application-form__form"
          formName="Application Form"
          formValues={formik.values}
          preSubmit={() => {
            formik.handleSubmit()
            return !formik.errors.name && !formik.errors.age && !formik.errors.phone
          }}
          postSubmit={formik.resetForm}
        >
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="application-form__label name">
            <FormControl
              id="name"
              name="name"
              type="text"
              placeholder={fieldNamePlaceholder}
              className="form-control"
              error={formik.touched.name && !!formik.errors.name}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </label>
          <FormSelect
            id="age"
            name="age"
            className={clsx("form-select", !formik.values.age && "placeholder")}
            error={formik.touched.age && !!formik.errors.age}
            onChange={formik.handleChange}
            value={formik.values.age}
          >
            <option key="someKey" value="" disabled="disabled" hidden="hidden">
              {fieldAgePlaceholder}
            </option>
            {fieldAgeOptions?.text.split("\n").map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            )) || null}
          </FormSelect>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="application-form__label phone">
            <FormControl
              as={InputMask}
              id="phone"
              name="phone"
              type="tel"
              placeholder={fieldPhonePlaceholder}
              className="form-control"
              error={formik.touched.phone && !!formik.errors.phone}
              mask={fieldPhoneMask}
              onChange={formik.handleChange}
              value={formik.values.phone}
            />
          </label>
          <div className="application-form__btn-wrapper">
            <img src={image.file.src} alt={image.alt} className="application-form__image" />
            <Button variant="contained" className="btn" type="submit">
              {btnTitle}
            </Button>
          </div>
        </NetlifyForm>
      </Container>
    </ApplicationFormWrapper>
  )
}

ApplicationForm.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  btnTitle: PropTypes.string.isRequired,
  image: PropTypes.exact({
    file: PropTypes.exact({
      src: PropTypes.string,
    }),
    alt: PropTypes.string,
  }).isRequired,
  fieldNamePlaceholder: PropTypes.string.isRequired,
  fieldAgePlaceholder: PropTypes.string.isRequired,
  fieldAgeOptions: PropTypes.exact({
    text: PropTypes.string,
  }).isRequired,
  fieldPhonePlaceholder: PropTypes.string.isRequired,
  fieldPhoneMask: PropTypes.string.isRequired,
}

export default ApplicationForm
