import styled from "@emotion/styled"

const CourseItemWrapper = styled.div`
  display: block;
  min-height: 200px;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  color: ${({ theme }) => theme.palette.common.black};

  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding: 16px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding: 24px;
  }

  &.course-item-with-price {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .course-item {
    &__title {
      white-space: pre-line;
      margin-bottom: 12px;
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};

      ${({ theme }) => theme.breakpoints.down("lg")} {
        font-size: 18px;
        line-height: 22px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        font-size: 24px;
        line-height: 26px;
      }
    }

    &__subtitle {
      white-space: pre-line;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        font-size: 14px;
        line-height: 18px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &__images {
      float: right;
      margin-top: -12px;
      margin-right: -12px;
      padding-left: 4px;
      padding-bottom: 4px;
    }

    &__image {
      display: block;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        max-width: 100px;
        max-height: 60px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        max-width: 180px;
        max-height: 108px;
      }

      &,
      * {
        overflow: hidden;
        border-radius: ${({ theme }) => theme.borderRadius}px;
      }
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    &__title-date {
      color: ${({ theme }) => theme.palette.gray["700"]};

      ${({ theme }) => theme.breakpoints.down("lg")} {
        font-size: 12px;
        line-height: 16px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        font-size: 14px;
        line-height: 21px;
      }
    }

    &__date {
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
      color: ${({ theme }) => theme.palette.primary.dark};

      ${({ theme }) => theme.breakpoints.down("lg")} {
        font-size: 12px;
        line-height: 16px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        font-size: 14px;
        line-height: 21px;
      }
    }

    &__title-price {
      text-align: right;
      color: ${({ theme }) => theme.palette.gray["700"]};

      ${({ theme }) => theme.breakpoints.down("lg")} {
        font-size: 12px;
        line-height: 16px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &__price {
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
      text-align: right;
      color: ${({ theme }) => theme.palette.primary.dark};

      ${({ theme }) => theme.breakpoints.down("lg")} {
        font-size: 20px;
        line-height: 24px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        font-size: 30px;
        line-height: 34px;
      }
    }
  }
`

export default CourseItemWrapper
