import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import TestLevelWrapper from "components/TestLevel/TestLevelWrapper"
import Container from "components/Container"
import Link from "components/Link"
import Button from "components/Button"
import TestLevelPagination from "components/TestLevel/TestLevelPagination"
import Image from "components/Image"

const TestLevel = ({
  baseUrl,
  globalContent: { logo },
  title,
  subtitle,
  buttonTitle,
  buttonNextTitle,
  imageBottom,
  time,
  tasks,
  results,
}) => {
  const [window, setWindow] = useState(0)
  const [answerIndex, setAnswerIndex] = useState(-1)
  const [balls, setBalls] = useState(0)
  const [minutes, setMinutes] = useState(Math.trunc(time))
  const [seconds, setSeconds] = useState(60 * (time - Math.trunc(time)))
  const _results = results.sort((a, b) => a.numberPoints - b.numberPoints)
  const [result, setResult] = useState(_results[0])

  useEffect(() => {
    if (window === tasks.length + 1) {
      const res = _results.find(
        (resItem, i) => balls > _results[i - 1]?.numberPoints && balls <= resItem?.numberPoints,
      )
      if (!res || !res.length) setResult(_results[0])
      if (!res?.length) setResult(res)
    }
  }, [_results, balls, tasks.length, window])

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)

    return () => {
      clearInterval(myInterval)
    }
  })

  useEffect(() => {
    if (!minutes && !seconds) setWindow(tasks.length + 1)
  }, [minutes, seconds, tasks.length])

  const handleNextWindow = () => {
    setAnswerIndex(-1)
    setWindow(window + 1)
    if (tasks[window - 1] && answerIndex + 1 === tasks[window - 1].correctAnswerNumber)
      setBalls(balls + 1)
  }

  return (
    <TestLevelWrapper id="test-level">
      <div className="test-level__wrapper">
        <Container className="test-level__container">
          <header>
            <Link to={baseUrl} aria-label={logo.alt} className="test-level__logo-wrapper">
              <Image
                className="header__logo-wrapper"
                image={logo}
                alt={logo.alt}
                objectFit="contain"
              />
            </Link>
          </header>
          <main className="test-level__main">
            {window === 0 && (
              <>
                <h1 className="test-level__title">{title}</h1>
                <p className="test-level__subtitle">{subtitle}</p>
                <Button
                  onClick={handleNextWindow}
                  className="test-level__button center"
                  variant="contained"
                >
                  {buttonTitle}
                </Button>
              </>
            )}
            {window !== tasks.length + 1 && window !== 0 && (
              <>
                <TestLevelPagination tasks={tasks} active={window - 1} />
                <div className="test-level__task-header">
                  <div
                    className="test-level__task-title"
                    dangerouslySetInnerHTML={{
                      __html: tasks[window - 1].task.childMarkdownRemark.html,
                    }}
                  />
                  {minutes === 0 && seconds === 0 ? null : (
                    <div className="test-level__task-time">
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.9889 16.4719L15.0849 13.5439V7.57957C15.0849 6.97987 14.6002 6.49512 14.0005 6.49512C13.4008 6.49512 12.916 6.97987 12.916 7.57957V14.0862C12.916 14.4278 13.0765 14.7499 13.3498 14.9537L17.6875 18.207C17.8827 18.3534 18.1105 18.4239 18.3371 18.4239C18.6678 18.4239 18.9932 18.2753 19.2057 17.9891C19.5659 17.5108 19.4682 16.8308 18.9889 16.4719Z"
                          fill="#72767A"
                        />
                        <path
                          d="M14 0C6.27993 0 0 6.27993 0 14C0 21.7201 6.27993 28 14 28C21.7201 28 28 21.7201 28 14C28 6.27993 21.7201 0 14 0ZM14 25.8311C7.47715 25.8311 2.16885 20.5229 2.16885 14C2.16885 7.47715 7.47715 2.16885 14 2.16885C20.5239 2.16885 25.8311 7.47715 25.8311 14C25.8311 20.5229 20.5229 25.8311 14 25.8311Z"
                          fill="#72767A"
                        />
                      </svg>
                      {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </div>
                  )}
                </div>
                <div className="test-level__test-answers">
                  {tasks[window - 1].answers.text.split("\n").map((answer, index) => {
                    if (!answer) return null
                    return (
                      <button
                        onClick={() => setAnswerIndex(index)}
                        className={`test-level__test-answer${
                          index === answerIndex ? " active" : ""
                        }`}
                        type="button"
                        key={answer}
                      >
                        {answer}
                      </button>
                    )
                  })}
                </div>
                <Button
                  disabled={answerIndex === -1}
                  onClick={handleNextWindow}
                  className="test-level__button next"
                  variant="contained"
                >
                  {buttonNextTitle}
                </Button>
              </>
            )}
            {window === tasks.length + 1 && (
              <>
                <div
                  className="test-level__result-content"
                  dangerouslySetInnerHTML={{
                    __html: result?.content?.childMarkdownRemark.html || "",
                  }}
                />
                {result?.buttonLink && result?.buttonTitle && (
                  <div className="test-level__result-link-wrappper">
                    <Link className="test-level__result-link" to={result.buttonLink}>
                      {result.buttonTitle}
                    </Link>
                  </div>
                )}
              </>
            )}
          </main>
          <footer>
            {(window === 0 || window === tasks.length + 1) && (
              <div className="test-level__image-wrapper">
                <img
                  src={imageBottom.file.src}
                  alt={imageBottom.alt}
                  className="test-level__image-bottom"
                />
              </div>
            )}
          </footer>
        </Container>
      </div>
    </TestLevelWrapper>
  )
}

TestLevel.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  globalContent: PropTypes.exact({
    logo: PropTypes.exact({
      gatsbyImageData: PropTypes.object,
      alt: PropTypes.string,
    }),
  }).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  buttonNextTitle: PropTypes.string.isRequired,
  imageBottom: PropTypes.exact({
    file: PropTypes.exact({
      src: PropTypes.string,
    }),
    alt: PropTypes.string,
  }).isRequired,
  time: PropTypes.number.isRequired,
  tasks: PropTypes.array.isRequired,
  results: PropTypes.array.isRequired,
}

export default TestLevel
