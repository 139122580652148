import styled from "@emotion/styled"

const TestimonialWrapper = styled.div`
  .testimonial-item {
    &__header {
      display: flex;
    }

    &__image-wrapper {
      width: 78px;
      height: 78px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      margin-bottom: 12px;
      flex: 0 0 78px;
      position: relative;

      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
      }

      &::before {
        top: -28px;
        width: 26px;
        height: 26px;
      }

      &::after {
        top: -16px;
        width: 20px;
        height: 20px;
      }
    }

    &__image {
      width: 68px;
      height: 68px;
      border-radius: 50%;
      overflow: hidden;

      * {
        overflow: hidden;
        border-radius: 50%;
      }
    }

    &__name {
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
      line-height: 24px;
      margin-bottom: 6px;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        font-size: 14px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        font-size: 20px;
      }
    }

    &__position {
      color: ${({ theme }) => theme.palette.gray["700"]};
      margin-bottom: 12px;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        font-size: 12px;
        line-height: 16px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
`

export default TestimonialWrapper
