import { useEffect } from "react"
import PropTypes from "prop-types"
import ContentWrapper from "components/Content/ContentWrapper"

const Content = ({ html, ...props }) => {
  useEffect(() => {
    if (html) {
      // youtube iframe
      const iframesYoutube = document.querySelectorAll('#content iframe[src*="youtube"]')
      if (iframesYoutube?.length) {
        const iframeWrap = document.createElement("div")
        iframeWrap.classList.add("video")
        iframesYoutube.forEach((iframe) => {
          iframe.parentNode.insertBefore(iframeWrap, iframe)
          iframeWrap.appendChild(iframe)
        })
      }

      // vitebsk.biz iframe
      const iframeVitbuz = document.querySelector('#content iframe[src*="vitebsk.biz/reviews"]')
      if (iframeVitbuz) {
        let height = 0
        window.addEventListener(
          "message",
          function (e) {
            const data = JSON.parse(e.data)
            if (height !== data.height) {
              iframeVitbuz.style.height = `${data.height}px`
              height = data.height
            }
          },
          false,
        )
        const refresh = () => {
          iframeVitbuz.contentWindow.postMessage("height", "*")
        }
        iframeVitbuz.addEventListener("load", refresh, false)
        setTimeout(refresh, 500)
      }

      // links
      const links = document.querySelectorAll("#content a[href*=http]")
      if (links?.length) {
        links.forEach((link) => {
          link.rel = "noopener noreferrer"
          link.target = "_blank"
        })
      }
    }
  }, [html])

  return <ContentWrapper {...props} id="content" dangerouslySetInnerHTML={{ __html: html }} />
}

Content.propTypes = {
  html: PropTypes.string.isRequired,
}

export default Content
