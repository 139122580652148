import { css } from "@emotion/react"

const reboot = (theme, isGray) => css`
  ${isGray
    ? `
  ${theme.breakpoints.up("lg")} {
    body {
      background-color: ${theme.palette.gray["50"]}
    }
  }

  header {
    box-shadow: 0 2px 0 rgba(0,0,0,.07);
  }

  footer {
    box-shadow: 0 -2px 0 rgba(0,0,0,.07);
  }

  .footer__wrapper {
    border-top: none!important;
  }
  `
    : ""}
  body {
    font-family: ${theme.typography.fontFamilyMontserrat};
    line-height: 1.5;
  }

  html,
  body,
  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
  }

  #gatsby-focus-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 80px;
  }

  a {
    text-decoration: none;
    color: ${theme.palette.primary.dark};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-top: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 1rem;
  }

  .grecaptcha-badge {
    z-index: 9998;

    &:hover {
      opacity: 1;
    }
  }
`

export default reboot
