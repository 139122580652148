import styled from "@emotion/styled"

const CustomPageWrapper = styled.section`
  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-left: 16px;
    margin-right: 16px;
  }

  .content-wrapper {
    background-color: ${({ theme }) => theme.palette.common.white};

    ${({ theme }) => theme.breakpoints.down("lg")} {
      padding-top: 48px;
      padding-bottom: 48px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      border-radius: ${({ theme }) => theme.borderRadius}px;
      box-shadow: 0 2px 72px rgba(0, 0, 0, 0.05);
      padding: 48px;
    }
  }

  .content {
    max-width: 860px;
  }

  .title {
    font-weight: 600;
    line-height: 1.14;
    margin-bottom: 1.5rem;
    max-width: 860px;

    ${({ theme }) => theme.breakpoints.down("lg")} {
      font-size: 26px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      font-size: 44px;
    }
  }

  .subtitle {
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
    margin-top: 16px;
    max-width: 860px;
  }

  .key-features {
    display: flex;
    flex-wrap: wrap;
    max-width: 860px;
    grid-row-gap: 13px;
    grid-column-gap: 13px;

    ${({ theme }) => theme.breakpoints.down("lg")} {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      margin-top: 48px;
      margin-bottom: 48px;
    }
  }

  .key-feature {
    border-radius: 0 30px 30px 30px;

    ${({ theme }) => theme.breakpoints.down("lg")} {
      padding: 18px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      padding: 22px;
    }

    &:nth-of-type(3n + 1) {
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: #ffffff;
    }

    &:nth-of-type(3n + 2),
    &:nth-of-type(3n + 3) {
      background-color: #eff3f5;
      color: #000000;
    }

    &__title {
      opacity: 0.7;
      margin-bottom: 6px;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        font-size: 14px;
        line-height: 18px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &__text {
      font-weight: 500;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        font-size: 18px;
        line-height: 24px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }

  .banner-list {
    ${({ theme }) => theme.breakpoints.up("md")} {
      float: right;
    }
  }

  .banner-item {
    position: relative;

    ${({ theme }) => theme.breakpoints.down("md")} {
      margin-left: auto;
      max-width: 140px;
      max-height: 129px;
    }

    ${({ theme }) => theme.breakpoints.up("md")} {
      margin-left: 10px;
      margin-bottom: 10px;
      max-width: 230px;
      max-height: 230px;
    }

    &:nth-of-type(even) .banner-item__icon {
      transform: scale(-1, 1);
    }

    &__image {
      position: absolute;
      top: 48.5%;
      left: 50%;
      width: 97%;
      height: 93.5%;
      border-radius: 50%;
      transform: translate(-50%, -50%);

      * {
        overflow: hidden;
        border-radius: ${({ theme }) => theme.borderRadius}px;
      }
    }

    &__icon {
      ${({ theme }) => theme.breakpoints.down("md")} {
        width: 100%;
        height: 100%;
      }
    }
  }

  .banner-item + .banner-item {
    ${({ theme }) => theme.breakpoints.down("md")} {
      margin-right: auto;
      margin-left: 0;
      margin-top: -20px;
      margin-bottom: 20px;
    }
  }
`

export default CustomPageWrapper
