import { useState } from "react"
import PropTypes from "prop-types"
import ReactPlayer from "react-player"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

const MyReactPlayer = styled(ReactPlayer)`
  position: relative;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-bottom: 100%;
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding-bottom: 56.25%;
  }

  iframe,
  .react-player__preview {
    position: absolute;
    margin: 0;
    padding: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .react-player {
    &__shadow {
      background: ${({ theme }) => theme.palette.common.white} !important;
      box-shadow: 0 0 8px rgb(0, 0, 0, 0.25);
    }

    &__play-icon {
      border-color: transparent transparent transparent ${({ theme }) => theme.palette.primary.main} !important;
      border-radius: 0;
    }
  }
`

const Video = ({ youtubeLink, imageDesktop, imageMobile, ...props }) => {
  const [light, setLight] = useState(imageDesktop.file.url)

  const config = {
    youtube: {
      playerVars: {
        autoplay: 1,
        hl: "en",
        rel: 0,
        showinfo: 0,
        modestbranding: 1,
        cc_load_policy: 0,
        iv_load_policy: 3,
      },
    },
  }

  const styles = css`
    .react-player__preview {
        @media (max-width: 480px) {
          background-image: url("${imageMobile.file.url}") !important;
        }
      }
    }
  `

  return (
    <MyReactPlayer
      {...props}
      url={youtubeLink}
      config={config}
      playing={true}
      controls={true}
      light={light}
      volume={1}
      muted={true}
      css={styles}
      width="100%"
      height="0"
      onReady={() => setLight("")}
      onEnded={() => setLight(imageDesktop.file.url)}
    />
  )
}

Video.propTypes = {
  youtubeLink: PropTypes.string.isRequired,
  imageDesktop: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  imageMobile: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
}

export default Video
