const FlagIcon = (props) => {
  return (
    <svg
      {...props}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6979 13.1406H1.24799C1.08279 13.1406 0.949219 13.007 0.949219 12.8418V2.2724C0.949219 2.1072 1.08279 1.97363 1.24799 1.97363H17.7014C17.8666 1.97363 18.0002 2.1072 18.0002 2.2724V12.8453C17.9967 13.007 17.8631 13.1406 17.6979 13.1406Z"
        fill="#41479B"
      />
      <path
        d="M17.9961 2.26537C17.9961 2.10369 17.866 1.97363 17.7043 1.97363H16.3897L10.9451 5.5413V1.97363H8.00663V5.5413L2.55847 1.97363H1.24389C1.0822 1.97363 0.952148 2.10369 0.952148 2.26537V3.0246L5.6235 6.08611H0.952148V9.0246H5.6235L0.952148 12.0861V12.8453C0.952148 13.007 1.0822 13.1371 1.24389 13.1371H2.55847L8.00311 9.56942V13.1371H10.9416V9.57645L16.3862 13.1441H17.7008C17.8625 13.1441 17.9926 13.0141 17.9926 12.8524V12.0931L13.3212 9.03163H17.9961V6.09314H13.3247L17.9961 3.02811V2.26537Z"
        fill="#F5F5F5"
      />
      <path
        d="M17.9961 6.67662H10.3546V1.97363H8.59362V6.67662H0.952148V8.44112H8.59362V13.1406H10.3546V8.44112H17.9961V6.67662Z"
        fill="#FF4B55"
      />
      <path
        d="M6.90724 9.02793L0.959961 12.8873C0.981051 13.0279 1.09704 13.1404 1.24819 13.1404H1.6524L7.98984 9.02441H6.90724V9.02793Z"
        fill="#FF4B55"
      />
      <path
        d="M12.4814 9.02734H11.4023L17.7292 13.1363C17.8769 13.1222 17.9929 12.9992 17.9929 12.8481V12.6055L12.4814 9.02734Z"
        fill="#FF4B55"
      />
      <path
        d="M0.952734 2.59246L6.33762 6.08983H7.41671L1.12145 2.00195C1.01952 2.04765 0.949219 2.14958 0.949219 2.26909C0.952734 2.26909 0.952734 2.59246 0.952734 2.59246Z"
        fill="#FF4B55"
      />
      <path
        d="M12.0209 6.08962L17.9857 2.21616C17.9611 2.07908 17.8451 1.97363 17.701 1.97363H17.2792L10.9453 6.08962H12.0209Z"
        fill="#FF4B55"
      />
      <path
        d="M0.660808 16.9969C0.295255 16.9969 0 16.7016 0 16.3361V1.65769C0 1.29916 0.29174 1.00391 0.653779 1.00391H0.657294C1.02285 1.00391 1.3181 1.29916 1.3181 1.66471V16.3361C1.3181 16.7016 1.02285 16.9969 0.660808 16.9969Z"
        fill="#32393F"
      />
    </svg>
  )
}

export default FlagIcon
