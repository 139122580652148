const breakpoints = {
  values: {
    sm: 480,
    md: 768,
    lg: 1024,
    xl: 1200,
  },
}
const typography = {
  rootFontSize: 16,
  fontFamily: `system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
}
typography.pxToRem = (value) => `${value / typography.rootFontSize}rem`

const theme = {
  breakpoints: {
    ...breakpoints,
    up: (key) => `@media (min-width: ${breakpoints.values[key]}px)`,
    down: (key) => `@media (max-width: ${breakpoints.values[key] - 0.02}px)`,
    between: (min, max) =>
      `@media (min-width: ${breakpoints.values[min]}px) and (max-width: ${
        breakpoints.values[max] - 0.02
      }px)`,
  },
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    primary: {
      main: "#0176d8",
      dark: "#00246E",
    },
    gray: {
      50: "#FCFEFF",
      100: "#F4F5F6",
      200: "#EFF3F5",
      300: "#e3e3e3",
      400: "#A3A3A3",
      700: "#515867",
    },
    error: {
      light: "#ffebeb",
    },
  },
  borderRadius: 20,
  typography: {
    ...typography,
    fontFamilyMontserrat: `Montserrat, ${typography.fontFamily}`,
    fontFamilyInter: `Inter, ${typography.fontFamily}`,
    fontSize: "16px",
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    lineHeight: 1.5,
  },
  shadows: ["0 6px 8px rgba(26,28,30,.07)"],
}

export default theme
