import PropTypes from "prop-types"
import TestimonialWrapper from "components/Testimonial/TestimonialWrapper"
import Image from "components/Image"

const Testimonial = ({ image, name, position, text }) => (
  <TestimonialWrapper className="testimonial-item">
    <div className="testimonial-item__header">
      <div className="testimonial-item__image-wrapper">
        <Image
          className="testimonial-item__image"
          image={image}
          alt={image.alt}
          objectFit="cover"
        />
      </div>
      <div className="testimonial-item__content">
        <h2 className="testimonial-item__name">{name}</h2>
        <p className="testimonial-item__position">{position}</p>
      </div>
    </div>
    <p className="testimonial-item__comment">{text?.text || ""}</p>
  </TestimonialWrapper>
)

Testimonial.propTypes = {
  image: PropTypes.exact({
    gatsbyImageData: PropTypes.object,
    alt: PropTypes.string,
  }).isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  text: PropTypes.exact({
    text: PropTypes.string,
  }).isRequired,
}

export default Testimonial
