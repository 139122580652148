import styled from "@emotion/styled"

const TeachersWrapper = styled.section`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .swiper {
    ${({ theme }) => theme.breakpoints.down("xl")} {
      padding-right: 16px;
      padding-left: 16px;
      padding-top: 12px;
    }

    ${({ theme }) => theme.breakpoints.up("xl")} {
      padding-right: 20px;
      padding-left: 20px;
      padding-top: 29px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 40%;
    width: var(--swiper-navigation-size);
    background-color: ${({ theme }) => theme.palette.gray["300"]};
    border-radius: 50%;

    ${({ theme }) => theme.breakpoints.down("lg")} {
      padding: 17px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      padding: 24px;
    }

    &:after {
      content: none;
    }

    &::before {
      content: "";
      display: block;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMyAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjI5MjggMC4zNzQwOTlDMTAuNjgzMyAtMC4wMTY0MjUgMTEuMzE2NSAtMC4wMTY0MjUzIDExLjcwNyAwLjM3NDA5OUwxMi4yOTA5IDAuOTU4MDE3QzEyLjY4MTIgMS4zNDgyOSAxMi42ODE1IDEuOTgwOTcgMTIuMjkxNiAyLjM3MTZMNS4wOTI0MiA5LjU4MzY2SDIxLjMzMzNDMjEuODg1NSA5LjU4MzY2IDIyLjMzMzMgMTAuMDMxNCAyMi4zMzMzIDEwLjU4MzdWMTEuNDE3QzIyLjMzMzMgMTEuOTY5MyAyMS44ODU1IDEyLjQxNyAyMS4zMzMzIDEyLjQxN0g1LjA5MjQyTDEyLjI5MTYgMTkuNjI5QzEyLjY4MTUgMjAuMDE5NyAxMi42ODEyIDIwLjY1MjMgMTIuMjkwOSAyMS4wNDI2TDExLjcwNyAyMS42MjY1QzExLjMxNjUgMjIuMDE3MSAxMC42ODMzIDIyLjAxNzEgMTAuMjkyOCAyMS42MjY1TDAuMzczNjk5IDExLjcwNzRDLTAuMDE2ODI0NyAxMS4zMTY5IC0wLjAxNjgyNjYgMTAuNjgzNyAwLjM3MzY5OSAxMC4yOTMyTDEwLjI5MjggMC4zNzQwOTlaIiBmaWxsPSIjMDE3Q0UzIi8+Cjwvc3ZnPgo=");
      background-size: contain;
      background-repeat: no-repeat;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        width: 16px;
        height: 16px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        width: 23px;
        height: 23px;
      }
    }
  }

  .swiper-button-prev {
    ${({ theme }) => theme.breakpoints.down("sm")} {
      left: 16px;
    }

    ${({ theme }) => theme.breakpoints.up("sm")} {
      left: 0;
    }
  }

  .swiper-button-next {
    ${({ theme }) => theme.breakpoints.down("sm")} {
      right: 16px;
    }

    ${({ theme }) => theme.breakpoints.up("sm")} {
      right: 0;
    }

    &::before {
      transform: rotate(180deg);
    }
  }

  .swiper-slide {
    ${({ theme }) => theme.breakpoints.down("md")} {
      width: 100%;
    }

    ${({ theme }) => theme.breakpoints.up("md")} {
      width: calc(100% / 3);
    }

    &:nth-of-type(odd) .teacher-item__fireworks {
      bottom: 25%;
    }

    &:nth-of-type(even) .teacher-item__fireworks {
      top: 0;
    }
  }

  .teachers__container {
    ${({ theme }) => theme.breakpoints.down("sm")} {
      padding-left: 0;
      padding-right: 0;
    }
  }
`

export default TeachersWrapper
