import styled from "@emotion/styled"

const InstagramWrapper = styled.section`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .swiper {
    ${({ theme }) => theme.breakpoints.down("sm")} {
      padding-left: 16px;
      padding-right: 16px;
    }

    ${({ theme }) => theme.breakpoints.up("sm")} {
      border-radius: ${({ theme }) => theme.borderRadius}px;
    }
  }

  .swiper-slide {
    ${({ theme }) => theme.breakpoints.down("sm")} {
      border-radius: ${({ theme }) => theme.borderRadius}px;
      overflow: hidden;
    }

    ${({ theme }) => theme.breakpoints.down("sm")} {
      width: 100%;
      height: 100%;
    }

    ${({ theme }) => theme.breakpoints.between("sm", "md")} {
      width: calc(100% / 2 - 6px);
      height: calc(100% / 2);
    }

    ${({ theme }) => theme.breakpoints.between("md", "lg")} {
      width: calc(100% / 3 - 6px);
      height: calc(100% / 3);
    }

    ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
      width: calc(100% / 5 - 6px);
      height: calc(100% / 5);
    }

    ${({ theme }) => theme.breakpoints.up("xl")} {
      width: calc(100% / 6 - 6px);
      height: calc(100% / 6);
    }
  }

  .instagram {
    &__container {
      ${({ theme }) => theme.breakpoints.down("sm")} {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &-item {
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      position: relative;

      & > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__skeleton {
      background-color: rgba(0, 0, 0, 0.11);
      animation: 1.5s ease-in-out 0.5s infinite normal none running animation-loading;
    }
  }

  @keyframe animation-loading {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.4;
    }

    100% {
      opacity: 1;
    }
  }
`

export default InstagramWrapper
