import styled from "@emotion/styled"

const ContentWrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 1rem;
    line-height: 1.3;
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 18px;
  }

  p {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 1rem;
    line-height: 1.8;
  }

  .gatsby-resp-image-wrapper,
  .video {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    &,
    & * {
      border-radius: ${({ theme }) => theme.borderRadius}px;
      overflow: hidden;
    }
  }

  .video {
    width: 100%;
    padding-top: 56.25%;
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  ol,
  ul {
    padding-left: 2rem;
  }

  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }

  dt {
    font-weight: 700;
  }

  dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
  }

  blockquote {
    margin: 0 0 1rem;
    padding: 0 1rem;
    color: #6a737d;
    border-left: 4px solid #dfe2e5;
  }

  a {
    color: #0176d8;
  }
`

export default ContentWrapper
