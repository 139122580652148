import PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"

const Link = ({ to, ...props }) => {
  if (/^(http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@\-/]))?$/.test(to))
    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a href={to || "/"} target="_blank" rel="noopener noreferrer" {...props} />
    )
  return <GatsbyLink to={to || "/"} {...props} />
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
}

export default Link
