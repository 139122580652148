import styled from "@emotion/styled"

const NewsWrapper = styled.section`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding-top: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-top: 40px;
    padding-bottom: 18px;
  }

  .swiper {
    ${({ theme }) => theme.breakpoints.down("sm")} {
      padding-left: 16px;
      padding-right: 16px;
    }

    ${({ theme }) => theme.breakpoints.down("lg")} {
      padding-top: 4px;
      padding-bottom: 46px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      padding-top: 17px;
      padding-bottom: 56px;
    }

    &-slide {
      ${({ theme }) => theme.breakpoints.down("md")} {
        width: 100%;
      }

      ${({ theme }) => theme.breakpoints.up("md")} {
        width: calc(50% - 4px);
        padding-left: 6px;
        padding-right: 12px;
      }
    }

    &-pagination-bullet {
      border: 5px solid ${({ theme }) => theme.palette.common.white};
    }
  }

  .news__container {
    ${({ theme }) => theme.breakpoints.down("sm")} {
      padding-left: 0;
      padding-right: 0;
    }
  }
`

export default NewsWrapper
