import PropTypes from "prop-types"
import { Global, ThemeProvider } from "@emotion/react"
import theme from "theme"
import fontMontserrat from "styles/fontMontserrat"
import fontInter from "styles/fontInter"
import modernNormalize from "styles/modernNormalize"
import reboot from "styles/reboot"
import swiper from "styles/swiper"
// import CookieConsent from "components/CookieConsent"

const Layout = ({ children, bgGray }) => (
  <ThemeProvider theme={theme}>
    <Global
      styles={[fontMontserrat, fontInter, modernNormalize, (t) => reboot(t, bgGray), swiper]}
    />
    {/* <CookieConsent /> */}
    {children}
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  bgGray: PropTypes.bool,
}

Layout.defaultProps = {
  bgGray: false,
}

export default Layout
