import PropTypes from "prop-types"
import TestsWrapper from "components/Tests/TestsWrapper"
import Container from "components/Container"
import Title from "components/Title"
import Subtitle from "components/Subtitle"
import TestItem from "components/TestItem/TestItem"

const Tests = ({ title, subtitle, btnTitle, tests }) => (
  <TestsWrapper id="tests" className="tests">
    <Container>
      <Title>{title}</Title>
      {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
      <div className="tests__list">
        {tests.map(({ id, ...props }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <TestItem key={`${id}${i}`} btnTitle={btnTitle} {...props} />
        ))}
      </div>
    </Container>
  </TestsWrapper>
)

Tests.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  btnTitle: PropTypes.string.isRequired,
  tests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
}

Tests.defaultProps = {
  subtitle: "",
}

export default Tests
