import styled from "@emotion/styled"

const Title = styled.p`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  color: ${({ theme }) => theme.palette.primary.dark};
  text-align: center;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 12px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: 44px;
    line-height: 50px;
    margin-bottom: 16px;
  }
`

export default Title
