const FireworkIcon = (props) => (
  <svg
    {...props}
    width="87"
    height="87"
    viewBox="0 0 87 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.94702 11.0645C4.94702 10.5122 5.39474 10.0645 5.94702 10.0645H9.06467C9.61695 10.0645 10.0647 10.5122 10.0647 11.0645V14.1821C10.0647 14.7344 9.61695 15.1821 9.06467 15.1821H5.94702C5.39474 15.1821 4.94702 14.7344 4.94702 14.1821V11.0645Z"
      fill="#83D947"
    />
    <path
      d="M76.5942 41.7705C76.5942 41.2182 77.042 40.7705 77.5942 40.7705H80.7119C81.2642 40.7705 81.7119 41.2182 81.7119 41.7705V44.8882C81.7119 45.4404 81.2642 45.8882 80.7119 45.8882H77.5942C77.042 45.8882 76.5942 45.4404 76.5942 44.8882V41.7705Z"
      fill="#32BEA6"
    />
    <path
      d="M41.7705 74.0352C41.2182 74.0352 40.7705 74.4829 40.7705 75.0352V78.1528C40.7705 78.7051 41.2182 79.1528 41.7705 79.1528H43.3293L45.0352 76.594L43.3293 74.0352H41.7705Z"
      fill="#9D6B5D"
    />
    <path
      d="M43.3293 74.0352H44.8882C45.4404 74.0352 45.8882 74.4829 45.8882 75.0352V78.1528C45.8882 78.7051 45.4404 79.1528 44.8882 79.1528H43.3293V74.0352Z"
      fill="#895050"
    />
    <path
      d="M41.9412 0C41.3889 0 40.9412 0.447715 40.9412 1V19.4706C40.9412 20.0229 41.3889 20.4706 41.9412 20.4706H43.5L45.2059 10.2353L43.5 0H41.9412Z"
      fill="#FF6B6B"
    />
    <path
      d="M55.0565 14.3839C54.6659 13.9933 54.6659 13.3602 55.0565 12.9697L60.8787 7.14739C61.2693 6.75687 61.9024 6.75687 62.2929 7.14739L64.4969 9.35136C64.8874 9.74188 64.8874 10.375 64.4969 10.7656L58.6747 16.5878C58.2841 16.9784 57.651 16.9784 57.2604 16.5878L55.0565 14.3839Z"
      fill="#FFB454"
    />
    <path
      d="M55.0528 43.3311C54.6623 42.9406 54.6623 42.3074 55.0528 41.9169L57.2568 39.713C57.6473 39.3224 58.2805 39.3224 58.671 39.713L64.4932 45.5352C64.8838 45.9257 64.8838 46.5589 64.4932 46.9494L62.2893 49.1534C61.8988 49.5439 61.2656 49.5439 60.8751 49.1534L55.0528 43.3311Z"
      fill="#FFB454"
    />
    <path
      d="M12.7942 57.2939C12.7942 56.7417 13.2419 56.2939 13.7942 56.2939H16.9118C17.4641 56.2939 17.9118 56.7417 17.9118 57.2939V65.5292C17.9118 66.0815 17.4641 66.5292 16.9118 66.5292H13.7942C13.2419 66.5292 12.7942 66.0815 12.7942 65.5292V57.2939Z"
      fill="#4793FF"
    />
    <path
      d="M12.7942 77.7646C12.7942 77.2124 13.2419 76.7646 13.7942 76.7646H16.9118C17.4641 76.7646 17.9118 77.2124 17.9118 77.7646V85.9999C17.9118 86.5522 17.4641 86.9999 16.9118 86.9999H13.7942C13.2419 86.9999 12.7942 86.5522 12.7942 85.9999V77.7646Z"
      fill="#4793FF"
    />
    <path
      d="M20.4707 70.0879C20.4707 69.5356 20.9184 69.0879 21.4707 69.0879H29.706C30.2583 69.0879 30.706 69.5356 30.706 70.0879V73.2055C30.706 73.7578 30.2583 74.2055 29.706 74.2055H21.4707C20.9184 74.2055 20.4707 73.7578 20.4707 73.2055V70.0879Z"
      fill="#4793FF"
    />
    <path
      d="M0 70.0879C0 69.5356 0.447715 69.0879 1 69.0879H9.23529C9.78758 69.0879 10.2353 69.5356 10.2353 70.0879V73.2055C10.2353 73.7578 9.78758 74.2055 9.23529 74.2055H1C0.447716 74.2055 0 73.7578 0 73.2055V70.0879Z"
      fill="#4793FF"
    />
    <path
      d="M69.0884 57.2939C69.0884 56.7417 69.5361 56.2939 70.0884 56.2939H73.206C73.7583 56.2939 74.206 56.7417 74.206 57.2939V65.5292C74.206 66.0815 73.7583 66.5292 73.206 66.5292H70.0884C69.5361 66.5292 69.0884 66.0815 69.0884 65.5292V57.2939Z"
      fill="#FFB454"
    />
    <path
      d="M69.0884 77.7646C69.0884 77.2124 69.5361 76.7646 70.0884 76.7646H73.206C73.7583 76.7646 74.206 77.2124 74.206 77.7646V85.9999C74.206 86.5522 73.7583 86.9999 73.206 86.9999H70.0884C69.5361 86.9999 69.0884 86.5522 69.0884 85.9999V77.7646Z"
      fill="#FFB454"
    />
    <path
      d="M76.7646 70.0879C76.7646 69.5356 77.2124 69.0879 77.7646 69.0879H85.9999C86.5522 69.0879 86.9999 69.5356 86.9999 70.0879V73.2055C86.9999 73.7578 86.5522 74.2055 85.9999 74.2055H77.7646C77.2124 74.2055 76.7646 73.7578 76.7646 73.2055V70.0879Z"
      fill="#FFB454"
    />
    <path
      d="M56.2942 70.0879C56.2942 69.5356 56.7419 69.0879 57.2942 69.0879H65.5295C66.0818 69.0879 66.5295 69.5356 66.5295 70.0879V73.2055C66.5295 73.7578 66.0818 74.2055 65.5295 74.2055H57.2942C56.7419 74.2055 56.2942 73.7578 56.2942 73.2055V70.0879Z"
      fill="#FFB454"
    />
    <path
      d="M43.5 0H45.0588C45.6111 0 46.0588 0.447715 46.0588 1V19.4706C46.0588 20.0229 45.6111 20.4706 45.0588 20.4706H43.5V0Z"
      fill="#FF3D7D"
    />
    <path
      d="M41.9412 35.8232C41.3889 35.8232 40.9412 36.271 40.9412 36.8232V55.2938C40.9412 55.8461 41.3889 56.2938 41.9412 56.2938H43.5L45.2059 46.0585L43.5 35.8232H41.9412Z"
      fill="#FF6B6B"
    />
    <path
      d="M43.5 35.8232H45.0588C45.6111 35.8232 46.0588 36.271 46.0588 36.8232V55.2938C46.0588 55.8461 45.6111 56.2938 45.0588 56.2938H43.5V35.8232Z"
      fill="#FF3D7D"
    />
    <path
      d="M22.4879 46.9454C22.0973 46.5549 22.0973 45.9217 22.4879 45.5312L28.3101 39.7089C28.7007 39.3184 29.3338 39.3184 29.7243 39.7089L31.9283 41.9129C32.3188 42.3034 32.3188 42.9366 31.9283 43.3271L26.106 49.1493C25.7155 49.5399 25.0824 49.5399 24.6918 49.1494L22.4879 46.9454Z"
      fill="#FFE278"
    />
    <path
      d="M22.4915 10.7628C22.101 10.3722 22.101 9.73908 22.4915 9.34856L24.6955 7.14459C25.086 6.75407 25.7192 6.75407 26.1097 7.14459L31.932 12.9669C32.3225 13.3574 32.3225 13.9905 31.932 14.3811L29.728 16.585C29.3375 16.9756 28.7043 16.9756 28.3138 16.585L22.4915 10.7628Z"
      fill="#FFE278"
    />
    <path
      d="M51.1765 26.5879C51.1765 26.0356 51.6242 25.5879 52.1765 25.5879H70.6471C71.1994 25.5879 71.6471 26.0356 71.6471 26.5879V29.7055C71.6471 30.2578 71.1994 30.7055 70.6471 30.7055H52.1765C51.6242 30.7055 51.1765 30.2578 51.1765 29.7055V26.5879Z"
      fill="#FF3D7D"
    />
    <path
      d="M15.353 26.5879C15.353 26.0356 15.8007 25.5879 16.353 25.5879H34.8236C35.3759 25.5879 35.8236 26.0356 35.8236 26.5879V29.7055C35.8236 30.2578 35.3759 30.7055 34.8236 30.7055H16.353C15.8007 30.7055 15.353 30.2578 15.353 29.7055V26.5879Z"
      fill="#FF6B6B"
    />
  </svg>
)

export default FireworkIcon
