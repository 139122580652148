import styled from "@emotion/styled"

const TeacherWrapper = styled.div`
  max-width: 298px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down("md")} {
    text-align: center;
  }

  .teacher-item {
    &__image-wrapper {
      position: relative;

      ${({ theme }) => theme.breakpoints.down("md")} {
        margin-left: auto;
        margin-right: auto;
      }

      ${({ theme }) => theme.breakpoints.down("lg")} {
        max-width: 237px;
        max-height: 262px;
        margin-bottom: 10px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        max-width: 297px;
        max-height: 329px;
        margin-bottom: 33px;
      }
    }

    &__image {
      width: 100%;
      height: auto;
      mask-repeat: no-repeat;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        mask-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjM3IiBoZWlnaHQ9IjI2MiIgdmlld0JveD0iMCAwIDIzNyAyNjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxtYXNrIGlkPSJtYXNrMCIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMCIgeT0iMCIgd2lkdGg9IjIzNyIgaGVpZ2h0PSIyNjIiPgo8cGF0aCBkPSJNMC42NDg3OTEgMTA0Ljk2MUMxMi4xMzE0IDIyNy4zNTUgNzcuMTk5NCAyNTguODIzIDEwOC4yOTggMjU5LjI1OEMxMzYuMzUyIDI2My4wNjQgMTg5LjE5OCAyNjUuNzgyIDE5MS44MDggMjM4LjM4MUMxOTQuMjU4IDIxMi42NTYgMjA2LjgxNCAxNzkuOTg5IDIxNC42NDMgMTY2Ljk0MUMyMzcuODY5IDEzNy4xOSAyMzcuODA0IDk5LjA4OSAyMzUuMTk0IDgxLjc5OTlDMjE0LjY0MyAtMTQuMTA2IC0xMy43MDQ1IC00OC4wMzE2IDAuNjQ4NzkxIDEwNC45NjFaIiBmaWxsPSIjRTNFNEU0Ii8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMCkiPgo8cmVjdCB4PSItNTUuNzg1MiIgeT0iLTM0LjMzMjYiIHdpZHRoPSIzNTMuNjEyIiBoZWlnaHQ9IjMzNS4zNDQiIGZpbGw9ImJsYWNrIi8+CjwvZz4KPC9zdmc+Cg==");
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        mask-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjk3IiBoZWlnaHQ9IjMyOSIgdmlld0JveD0iMCAwIDI5NyAzMjkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPG1hc2sgaWQ9Im1hc2swIiBzdHlsZT0ibWFzay10eXBlOmFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIwIiB5PSIwIiB3aWR0aD0iMjk3IgogICAgICAgIGhlaWdodD0iMzI5Ij4KICAgIDxwYXRoCiAgICAgIGQ9Ik0wLjgxMzQ5NCAxMzIuMjI3QzE1LjIxMTEgMjg1LjY5MiA5Ni43OTczIDMyNS4xNDkgMTM1Ljc5MSAzMjUuNjk1QzE3MC45NjcgMzMwLjQ2NyAyMzcuMjI4IDMzMy44NzUgMjQwLjUgMjk5LjUxN0MyNDMuNTcyIDI2Ny4yNjIgMjU5LjMxNSAyMjYuMzAyIDI2OS4xMzIgMjA5Ljk0MkMyOTguMjU0IDE3Mi42MzkgMjk4LjE3MyAxMjQuODY1IDI5NC45IDEwMy4xODdDMjY5LjEzMiAtMTcuMDY1OCAtMTcuMTgzNSAtNTkuNjAzOSAwLjgxMzQ5NCAxMzIuMjI3WiIKICAgICAgZmlsbD0iI0UzRTRFNCIvPgogIDwvbWFzaz4KICA8ZyBtYXNrPSJ1cmwoI21hc2swKSI+CiAgICA8cmVjdCB4PSItNDkiIHk9Ii04MSIgd2lkdGg9IjM4NiIgaGVpZ2h0PSI1NzgiIGZpbGw9ImJsYWNrIi8+CiAgPC9nPgo8L3N2Zz4K");
      }
    }

    &__fireworks {
      position: absolute;
      right: 0;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        width: 57px;
        height: 57px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        width: 87px;
        height: 87px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        grid-row-gap: 4px;
        grid-column-gap: 4px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        grid-row-gap: 12px;
        grid-column-gap: 12px;
      }
    }

    &__name {
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
      line-height: 26px;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        font-size: 18px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        font-size: 24px;
      }
    }

    &__position {
      font-size: 14px;
      line-height: 20px;
      color: ${({ theme }) => theme.palette.primary.dark};
    }

    &__short-desc {
      font-size: 14px;
      line-height: 20px;
    }

    &__btn {
      ${({ theme }) => theme.breakpoints.down("lg")} {
        display: none;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        font-family: ${({ theme }) => theme.typography.fontFamilyInter};
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        color: ${({ theme }) => theme.palette.primary.main};
        display: inline-flex;
        align-items: center;
        padding: 0;

        &::after {
          content: "";
          display: inline-block;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjU4NzcgMy45MjA3N0MxMC4yNjMxIDMuNTk2MTYgOS43MzY3NyAzLjU5NjE2IDkuNDEyMTYgMy45MjA3N0M5LjA4Nzc1IDQuMjQ1MTggOS4wODc1MSA0Ljc3MTA3IDkuNDExNjMgNS4wOTU3N0wxMy40NzQ5IDkuMTY2MzRINC4xNjY1OUMzLjcwNjM1IDkuMTY2MzQgMy4zMzMyNSA5LjUzOTQ0IDMuMzMzMjUgOS45OTk2N0MzLjMzMzI1IDEwLjQ1OTkgMy43MDYzNSAxMC44MzMgNC4xNjY1OCAxMC44MzNIMTMuNDc0OUw5LjQxMTYzIDE0LjkwMzZDOS4wODc1MSAxNS4yMjgzIDkuMDg3NzUgMTUuNzU0MiA5LjQxMjE2IDE2LjA3ODZDOS43MzY3NyAxNi40MDMyIDEwLjI2MzEgMTYuNDAzMiAxMC41ODc3IDE2LjA3ODZMMTUuOTU5NSAxMC43MDY4QzE2LjM1IDEwLjMxNjMgMTYuMzUgOS42ODMwOSAxNS45NTk1IDkuMjkyNTdMMTAuNTg3NyAzLjkyMDc3WiIgZmlsbD0iIzAxN0NFMyIvPgo8L3N2Zz4K");
          width: 20px;
          height: 20px;
        }
      }
    }
  }
`

export default TeacherWrapper
