import PropTypes from "prop-types"
import { getImage, withArtDirection } from "gatsby-plugin-image"
import Link from "components/Link"
import SlideImageWrapper from "components/Slides/SlideImageWrapper"
import Image from "components/Image"

const SlideImage = ({ imageDesktop, imageMobile, link }) => {
  const image = getImage(imageDesktop)
  const images = withArtDirection(image, [
    {
      media: "(max-width: 479.9px)",
      image: getImage(imageMobile),
    },
  ])
  const child = <Image image={images} alt={imageDesktop.alt} />
  if (!link)
    return (
      <SlideImageWrapper as="div" className="slide-image">
        {child}
      </SlideImageWrapper>
    )
  return (
    <SlideImageWrapper as={Link} to={link} className="slide-image">
      {child}
    </SlideImageWrapper>
  )
}

SlideImage.propTypes = {
  imageDesktop: PropTypes.exact({
    gatsbyImageData: PropTypes.object,
    alt: PropTypes.string,
  }).isRequired,
  imageMobile: PropTypes.exact({
    gatsbyImageData: PropTypes.object,
    alt: PropTypes.string,
  }).isRequired,
  link: PropTypes.string,
}

SlideImage.defaultProps = {
  link: "",
}

export default SlideImage
