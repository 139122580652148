const FireworkIcon = (props) => (
  <svg
    {...props}
    width="76"
    height="76"
    viewBox="0 0 76 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.3847 46.615L24.1197 39.1106L16.1614 33.3916C15.5481 35.0082 15.0213 36.3971 14.4082 38.0137L19.4079 45.0211L26.1971 49.8026L29.3846 49.4539L29.3847 46.615Z"
      fill="#FC6DAE"
    />
    <path
      d="M19.8216 56.1778L16.634 51.1319L10.9016 47.2578C10.2885 48.8744 9.76168 50.2633 9.14844 51.8799L12.0596 56.792L16.634 59.3655H19.1573L19.8216 56.1778Z"
      fill="#FC6DAE"
    />
    <path
      d="M26.1973 49.8028L31.0788 56.5419L37.9862 61.5916C39.6028 60.9785 40.9917 60.4517 42.6082 59.8384L36.789 52.5347L29.3849 46.6152C28.2699 47.7301 27.3121 48.688 26.1973 49.8028Z"
      fill="#F73D7F"
    />
    <path
      d="M16.6348 59.3654L19.3585 63.7042L24.1203 66.8509C25.7369 66.2377 27.1258 65.7108 28.7424 65.0977L24.9184 59.8381L19.8224 56.1777C18.7076 57.2926 17.7496 58.2505 16.6348 59.3654Z"
      fill="#F73D7F"
    />
    <path
      d="M34.1668 41.8332L27.7916 33.3913L18.7919 26.458C17.9563 28.6609 16.9977 31.1883 16.1621 33.3913C17.7619 34.9909 27.7752 45.0044 29.3855 46.6147L32.7324 45.3715L34.1668 41.8332Z"
      fill="#FFD92E"
    />
    <path
      d="M29.3848 46.6153C30.9844 48.2149 40.9979 58.2284 42.6081 59.8387C44.8111 59.003 47.3382 58.0445 49.5412 57.2089L42.6081 48.4777L34.1663 41.834C32.6462 43.3539 30.9046 45.0954 29.3848 46.6153Z"
      fill="#FCBE00"
    />
    <path
      d="M32.5722 33.8638H29.3848V30.6762C31.6682 28.3928 34.4718 25.5893 35.76 24.3008C38.3142 21.7465 39.7212 18.3503 39.7212 14.7378C39.7212 11.1255 38.3144 7.72931 35.76 5.17492C36.8748 4.06009 37.8328 3.10214 38.9476 1.9873C45.9666 9.00613 46.0234 20.4126 38.9476 27.4885L34.963 31.473L32.5722 33.8638Z"
      fill="#C6EA4B"
    />
    <path
      d="M45.3224 46.615H42.1348V43.4273C44.3447 41.2174 46.3017 39.2604 48.5101 37.052C51.9158 33.6463 56.444 31.7705 61.2606 31.7705C66.077 31.7705 70.6053 33.646 74.0112 37.052C72.8964 38.1668 71.9384 39.1247 70.8236 40.2396C68.187 37.6031 64.7238 36.2848 61.2606 36.2848C57.7974 36.2848 54.3344 37.6031 51.6978 40.2396C49.4909 42.4462 47.5354 44.402 45.3224 46.615Z"
      fill="#55C8FF"
    />
    <path d="M21.9629 6.70312H26.4709V11.2111H21.9629V6.70312Z" fill="#84DFFF" />
    <path d="M26.4707 15.7188H30.9787V20.2268H26.4707V15.7188Z" fill="#84DFFF" />
    <path d="M58.0273 42.7666H62.5354V47.2746H58.0273V42.7666Z" fill="#92E200" />
    <path
      d="M67.0432 56.2912C65.4666 56.2912 64.1119 56.2912 62.5352 56.2912C62.5352 54.7146 62.5352 51.7832 62.5352 51.7832H67.0432V56.2912Z"
      fill="#92E200"
    />
    <path d="M67.043 20.2266H71.551V24.7346H67.043V20.2266Z" fill="#55C8FF" />
    <path d="M49.0117 2.19434H53.5197V6.70235H49.0117V2.19434Z" fill="#C6EA4B" />
    <path
      d="M58.3577 17.6435L63.1372 14.4416L66.7908 9.21032C67.1283 7.42184 68.4167 6.14472 70.1911 5.81023L73.5809 3.99801L74.4074 1.59373C73.8499 1.03639 73.3709 0.557491 72.8135 0C70.6456 2.16775 68.2375 0.325629 64.7718 3.79139C61.9979 6.56517 62.509 9.06321 61.8809 10.562C61.5208 11.4215 60.5696 12.3727 59.7097 12.7332C58.6364 13.1831 57.1396 13.0436 55.3385 13.8976C53.6262 14.7095 52.0235 16.3116 51.2113 18.0248C49.9734 20.6357 50.8968 21.9165 49.1465 23.6671C49.7038 24.2246 50.1829 24.7035 50.7402 25.2608L52.8486 24.4343L54.957 21.0442C55.296 19.2468 56.5629 17.982 58.3577 17.6435Z"
      fill="#FC6DAE"
    />
    <path
      d="M37.3535 38.6454C36.8794 38.1713 21.0183 22.3102 20.5442 21.8359C19.9309 23.4524 19.4041 24.8414 18.791 26.4579C19.6456 27.3123 33.3115 40.9785 34.1659 41.8329L37.1032 41.5825L37.3535 38.6454Z"
      fill="#FCBE00"
    />
    <path
      d="M9.14901 51.8799C8.89085 52.5604 0.257858 75.32 0 76L9.14901 68.9136L16.6346 59.3656C15.7295 58.4604 10.0599 52.791 9.14901 51.8799Z"
      fill="#FFD92E"
    />
    <path
      d="M26.1977 49.8026C24.8689 48.4738 15.809 39.414 14.4088 38.0137C13.4557 40.5263 11.4592 45.7897 10.9023 47.258C11.9862 48.342 18.7352 55.0908 19.8223 56.178L24.1203 54.0371L26.1977 49.8026Z"
      fill="#FFD92E"
    />
    <path
      d="M64.166 15.02C66.0729 13.1131 66.3253 11.6646 66.7885 9.20996C63.839 12.1593 61.3048 14.6937 58.3555 17.6431C60.6676 17.2069 62.1957 16.9905 64.166 15.02Z"
      fill="#F73D7F"
    />
    <path
      d="M75.9995 3.18748C75.442 2.63014 74.9631 2.15124 74.4058 1.59375C72.9311 3.06832 71.664 4.33552 70.1895 5.81025C71.7241 5.52053 73.7623 5.42466 75.9995 3.18748Z"
      fill="#F73D7F"
    />
    <path
      d="M54.9551 21.0439C53.4802 22.5187 52.2132 23.786 50.7383 25.2606C51.2956 25.8181 51.7747 26.297 52.332 26.8545C54.4947 24.6918 54.6266 22.7843 54.9551 21.0439Z"
      fill="#F73D7F"
    />
    <path
      d="M49.5409 57.208C51.1575 56.5949 52.5464 56.0681 54.163 55.4548C53.6889 54.9807 37.8279 39.1196 37.3536 38.6455C36.2388 39.7603 35.281 40.7183 34.166 41.8331C35.0206 42.6875 48.6865 56.3536 49.5409 57.208Z"
      fill="#FF9000"
    />
    <path
      d="M16.6346 59.3652C15.7118 60.2882 0.922941 75.0769 0 75.9998C0.68056 75.7416 23.4402 67.1088 24.1201 66.8508C23.2151 65.9457 17.5455 60.2762 16.6346 59.3652Z"
      fill="#FCBE00"
    />
    <path
      d="M19.8223 56.1781C20.9061 57.262 27.6551 64.0109 28.7423 65.0981C31.255 64.145 36.5184 62.1485 37.9865 61.5916C36.6577 60.2628 27.598 51.2031 26.1976 49.8027C25.4262 50.5742 20.5987 55.4017 19.8223 56.1781Z"
      fill="#FCBE00"
    />
  </svg>
)

export default FireworkIcon
