import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Render inline SVG with fallback non-svg images
const Image = ({ svg, file, alt, image, ...props }) => {
  if (file?.contentType === "image/svg+xml") {
    // Inlined SVGs
    if (svg?.content.indexOf("mask") === -1)
      return <div dangerouslySetInnerHTML={{ __html: svg.content }} {...props} />

    // SVGs that can/should not be inlined
    return <img src={file.url} alt={alt} {...props} />
  }

  // Non SVG images
  if (image) return <GatsbyImage image={getImage(image)} alt={alt} {...props} />

  return null
}

Image.propTypes = {
  svg: PropTypes.shape({
    content: PropTypes.string,
  }),
  file: PropTypes.shape({
    contentType: PropTypes.string,
    url: PropTypes.string,
  }),
  alt: PropTypes.string.isRequired,
  image: PropTypes.object,
}

Image.defaultProps = {
  svg: null,
  file: null,
  image: null,
}

export default Image
