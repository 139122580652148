import React from "react"
import PropTypes from "prop-types"
import CustomPageWrapper from "components/CustomPageWrapper"
import Container from "components/Container"
import Gallery from "components/Gallery/Gallery"
import MessageIcon from "components/icons/MessageIcon"
import Content from "components/Content/Content"
import Image from "components/Image"

const Course = ({ title, subtitle, keyFeatures, content, photosTitle, photos, banners }) => (
  <CustomPageWrapper id="course">
    <Container className="container">
      <div className="content-wrapper">
        <div className="banner-list">
          {banners.map(({ id, alt, ...image }) => (
            <div key={id} className="banner-item">
              <MessageIcon className="banner-item__icon" />
              <Image
                className="banner-item__image"
                key={id}
                image={image}
                alt={alt}
                objectFit="contain"
              />
            </div>
          ))}
        </div>
        <h1 className="title">{title}</h1>
        <h2 className="subtitle">{subtitle.text}</h2>
        <div className="key-features">
          {keyFeatures.map(({ id, title: titleItem, text }) => (
            <div key={id} className="key-feature">
              <div className="key-feature__title">{titleItem}</div>
              <div className="key-feature__text">{text}</div>
            </div>
          ))}
        </div>
        <div className="content">
          <Content html={content.childMarkdownRemark.html} />
        </div>
        {!!photos?.length && <Gallery photosTitle={photosTitle} photos={photos} />}
      </div>
    </Container>
  </CustomPageWrapper>
)

Course.propTypes = {
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      gatsbyImageData: PropTypes.object,
      alt: PropTypes.string,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.exact({
    text: PropTypes.string,
  }).isRequired,
  keyFeatures: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
  content: PropTypes.exact({
    childMarkdownRemark: PropTypes.exact({
      html: PropTypes.string,
    }),
  }).isRequired,
  photosTitle: PropTypes.string,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      gatsbyImageData: PropTypes.object,
      alt: PropTypes.string,
    }),
  ),
}

Course.defaultProps = {
  photosTitle: "",
  photos: [],
}

export default Course
