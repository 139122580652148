import styled from "@emotion/styled"

const AnnouncementBarWrapper = styled.aside`
  background-color: #056fce;
  box-shadow: 0 2px 14px #4b799a;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;

  .announcement-bar {
    &__container {
      padding-top: 5px;
      padding-bottom: 5px;

      ${({ theme }) => theme.breakpoints.down("md")} {
        padding-right: 35px;
        padding-left: 16px;
      }

      ${({ theme }) => theme.breakpoints.up("md")} {
        padding-right: 55px;
        padding-left: 40px;
      }
    }

    &__link {
      ${({ theme }) => theme.breakpoints.down("md")} {
        display: flex;
        grid-row-gap: 10px;
        grid-column-gap: 10px;
      }

      ${({ theme }) => theme.breakpoints.up("md")} {
        display: flex;
        grid-row-gap: 20px;
        grid-column-gap: 20px;
        align-items: center;
        justify-content: center;
      }
    }

    &__image-wrapper {
      ${({ theme }) => theme.breakpoints.down("xl")} {
        max-width: 76px;
        min-width: 60px;
        max-height: 40px;
      }

      ${({ theme }) => theme.breakpoints.up("xl")} {
        max-width: 114px;
        max-height: 60px;
      }

      > {
        background-color: ${({ theme }) => theme.palette.common.white};
      }

      &,
      * {
        ${({ theme }) => theme.breakpoints.down("xl")} {
          overflow: hidden;
          border-radius: 3px;
        }

        ${({ theme }) => theme.breakpoints.up("xl")} {
          overflow: hidden;
          border-radius: 12px;
        }
      }
    }

    &__text {
      text-transform: uppercase;
      color: #fff;

      ${({ theme }) => theme.breakpoints.down("xl")} {
        font-size: 16px;
        line-height: 20px;
      }

      ${({ theme }) => theme.breakpoints.up("xl")} {
        font-size: 24px;
        line-height: 28px;
      }

      p {
        margin-bottom: 0;
      }
    }

    &__btn-close {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      background-color: transparent;
      border: none;
      padding: 0;

      ${({ theme }) => theme.breakpoints.down("xl")} {
        right: 10px;
        width: 16px;
        height: 16px;
      }

      ${({ theme }) => theme.breakpoints.up("xl")} {
        right: 27px;
        width: 24px;
        height: 24px;
      }

      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 2px;
        background-color: #fff;
        top: 0;

        ${({ theme }) => theme.breakpoints.down("xl")} {
          left: 7px;
          height: 17px;
        }

        ${({ theme }) => theme.breakpoints.up("xl")} {
          left: 12px;
          height: 25px;
        }
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }
`

export default AnnouncementBarWrapper
