import PropTypes from "prop-types"
import CourseItem from "components/CourseItem/CourseItem"
import CoursesWrapper from "components/Courses/CoursesWrapper"
import Container from "components/Container"
import Title from "components/Title"

const Courses = ({ baseUrl, title, courses }) => (
  <CoursesWrapper id="courses" className="courses">
    <Container>
      <Title>{title}</Title>
      <div className="courses__list">
        {courses.map(({ id, ...props }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <CourseItem key={`${id}${i}`} baseUrl={baseUrl} {...props} />
        ))}
      </div>
    </Container>
  </CoursesWrapper>
)

Courses.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
}

export default Courses
