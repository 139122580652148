import styled from "@emotion/styled"

const TestLevelWrapper = styled.main`
  margin-top: -80px;
  height: calc(100% + 80px);
  overflow: hidden;
  background: linear-gradient(180deg, #ffffff 7.78%, #daebff 100%);

  .test-level {
    &__wrapper {
      height: 100%;
      overflow: auto;
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
    }

    &__main {
      width: 100%;
    }

    &__logo-wrapper {
      display: block;
      margin-bottom: 15px;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        max-width: 101px;
        height: 79px;
        margin-top: 16px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        max-width: 121px;
        height: 95px;
        margin-top: 22px;
      }

      > * {
        width: 100%;
        height: 100%;
      }
    }

    &__image-wrapper {
      position: relative;
      overflow: hidden;
      padding-top: 20px;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        width: 236px;
        height: 199px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        width: 374px;
        height: 294px;
      }
    }

    &__image-bottom {
      position: absolute;

      left: 50%;
      transform: translateX(-50%);
      object-fit: contain;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        max-width: 236px;
        max-height: 222px;
        bottom: -43px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        max-width: 374px;
        max-height: 351px;
        bottom: -77px;
      }
    }

    &__title {
      text-align: center;
      font-weight: bold;
      line-height: 1.25;
      color: #000000;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        font-size: 18px;
        margin-bottom: 12px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        font-size: 44px;
        margin-bottom: 34px;
      }
    }

    &__subtitle {
      text-align: center;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        font-size: 14px;
        line-height: 1.25;
        margin-bottom: 40px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        font-weight: 600;
        font-size: 24px;
        line-height: 1;
        margin-bottom: 125px;
      }
    }

    &__button {
      border-radius: 8px;
      width: 360px;
      font-weight: 600;

      &.center {
        margin: 0 auto;
        display: block;
      }

      &.next {
        &:disabled {
          opacity: 0.5;
          pointer-events: none;
          cursor: not-allowed;
        }

        &::after {
          content: "";
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTk5ODQgMC42NjY5OTJMOC4zNTQ4NCAyLjMxMTk5TDE0Ljg2NDggOC44MzM2NkgwLjY2NjUwNFYxMS4xNjdIMTQuODY0OEw4LjM1NDg0IDE3LjY4ODdMOS45OTk4NCAxOS4zMzM3TDE5LjMzMzIgMTAuMDAwM0w5Ljk5OTg0IDAuNjY2OTkyWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");
          background-size: cover;
          display: inline-block;
          margin-left: 10px;

          ${({ theme }) => theme.breakpoints.down("lg")} {
            width: 12px;
            height: 12px;
          }

          ${({ theme }) => theme.breakpoints.up("lg")} {
            width: 18px;
            height: 18px;
          }
        }
      }

      ${({ theme }) => theme.breakpoints.down("md")} {
        width: 100%;
      }

      ${({ theme }) => theme.breakpoints.down("lg")} {
        font-size: 14px;
        height: 50px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        font-size: 24px;
        height: 72px;
      }
    }

    &__task-header {
      ${({ theme }) => theme.breakpoints.up("lg")} {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
    }

    &__task-title {
      font-weight: 600;
      color: #000000;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        margin-right: 20px;
        font-size: 24px;
        line-height: 34px;
      }
    }

    &__task-time {
      font-weight: 500;
      text-transform: uppercase;
      color: #72767a;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: nowrap;

      svg {
        margin-right: 6px;

        ${({ theme }) => theme.breakpoints.down("lg")} {
          width: 16px;
          height: 16px;
        }

        ${({ theme }) => theme.breakpoints.up("lg")} {
          width: 28px;
          height: 28px;
        }
      }

      ${({ theme }) => theme.breakpoints.down("lg")} {
        font-size: 14px;
        line-height: 18px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        font-size: 24px;
        line-height: 28px;
      }
    }

    &__test-answers {
      display: grid;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        margin-top: 15px;
        margin-bottom: 40px;
        grid-row-gap: 8px;
        grid-column-gap: 8px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        margin-top: 80px;
        margin-bottom: 40px;
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        grid-row-gap: 20px;
        grid-column-gap: 20px;
      }
    }

    &__test-answer {
      border-radius: 8px;
      border: 2px solid transparent;
      background-color: #fff;
      cursor: pointer;
      text-align: left;
      font-weight: 500;

      &.active {
        border: 2px solid #017ce3;
      }

      ${({ theme }) => theme.breakpoints.down("lg")} {
        padding: 12px 8px;
        font-size: 14px;
        line-height: 18px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        padding: 22px;
        font-size: 20px;
        line-height: 28px;
      }
    }

    &__result-content {
      text-align: center;
      max-width: 600px;
      margin: 0 auto;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: 600;
        margin-bottom: 0.5rem;
      }

      h1 {
        font-size: 28px;
      }

      h2 {
        font-size: 26px;
      }

      h3 {
        font-size: 24px;
      }

      h4 {
        font-size: 22px;
      }

      h5 {
        font-size: 20px;
      }

      h6 {
        font-size: 18px;
      }

      p {
        font-size: 16px;
        margin-bottom: 0.5rem;
      }
    }

    &__result-link-wrappper {
      margin-top: 30px;
      text-align: center;
    }

    &__result-link {
      display: inline-block;
      font-size: 16px;
      line-height: 20px;
      color: #017ce3;
      text-align: center;
      text-transform: uppercase;

      &::after {
        content: "";
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuNTg4NDEgMC45MjA3N0M3LjI2MzggMC41OTYxNTggNi43Mzc1IDAuNTk2MTU4IDYuNDEyODkgMC45MjA3NzFDNi4wODg0OCAxLjI0NTE4IDYuMDg4MjQgMS43NzEwNyA2LjQxMjM2IDIuMDk1NzdMMTAuNDc1NiA2LjE2NjM0SDEuMTY3MzJDMC43MDcwODEgNi4xNjYzNCAwLjMzMzk4NCA2LjUzOTQ0IDAuMzMzOTg0IDYuOTk5NjdDMC4zMzM5ODQgNy40NTk5MSAwLjcwNzA4IDcuODMzMDEgMS4xNjczMiA3LjgzMzAxSDEwLjQ3NTZMNi40MTIzNiAxMS45MDM2QzYuMDg4MjQgMTIuMjI4MyA2LjA4ODQ4IDEyLjc1NDIgNi40MTI4OSAxMy4wNzg2QzYuNzM3NSAxMy40MDMyIDcuMjYzOCAxMy40MDMyIDcuNTg4NDEgMTMuMDc4NkwxMi45NjAyIDcuNzA2NzhDMTMuMzUwNyA3LjMxNjI2IDEzLjM1MDcgNi42ODMwOSAxMi45NjAyIDYuMjkyNTdMNy41ODg0MSAwLjkyMDc3WiIgZmlsbD0iIzAxN0NFMyIvPgo8L3N2Zz4K");
        width: 13px;
        height: 13px;
        display: inline-block;
        margin-left: 10px;
      }
    }
  }
`

export default TestLevelWrapper
