import PropTypes from "prop-types"
import { Autoplay, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import * as Slides from "containers/slides"
import SliderWrapper from "components/Slider/SliderWrapper"
import Container from "components/Container"

const Slider = ({ slides }) => (
  <SliderWrapper id="slider" className="slider">
    <Container className="slider__container">
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={8}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        onClick={(swiper) => {
          swiper.autoplay.stop();
        }}
      >
        {slides
          .sort((a, b) => a.index - b.index)
          .map(({ component, id, ...props }) => {
            const Component = Slides[component]
            if (!Component) return null
            return (
              <SwiperSlide key={id}>
                <Component {...props} />
              </SwiperSlide>
            )
          })}
      </Swiper>
    </Container>
  </SliderWrapper>
)

Slider.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      index: PropTypes.string,
      component: PropTypes.string,
    }),
  ).isRequired,
}

export default Slider
