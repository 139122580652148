import React from "react"
import PropTypes from "prop-types"
import GalleryWrapper from "components/Gallery/GalleryWrapper"
import Image from "components/Image"

const Gallery = ({ photosTitle, photos }) =>
  photos?.length ? (
    <GalleryWrapper>
      {!!photosTitle && <p className="gallery__title">{photosTitle}</p>}
      <div className="gallery__photo-list">
        {photos.map(({ id, alt, ...photo }) => (
          <Image
            className="gallery__photo-item"
            key={id}
            image={photo}
            alt={alt}
            objectFit="contain"
          />
        ))}
      </div>
    </GalleryWrapper>
  ) : null

Gallery.propTypes = {
  photosTitle: PropTypes.string,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      gatsbyImageData: PropTypes.object,
      alt: PropTypes.string,
    }),
  ).isRequired,
}

Gallery.defaultProps = {
  photosTitle: "",
}

export default Gallery
