import styled from "@emotion/styled"

const TeacherModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;

  h3 {
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    font-size: 16px;
    line-height: 26px;
  }

  p {
    font-size: 16px;
    line-height: 26px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &:not(.show) {
    opacity: 0;
    pointer-events: none;
  }

  &.show {
    opacity: 1;
  }

  &:not(.show) .teacher-modal__dialog {
    transform: translateY(-25%);
  }

  &.show .teacher-modal__dialog {
    transform: translateY(0);
  }

  .teacher-modal {
    &__backdrop {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.25);
    }

    &__dialog {
      max-width: 800px;
      background-color: ${({ theme }) => theme.palette.common.white};
      position: relative;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      transition: 0.3s;

      ${({ theme }) => theme.breakpoints.down("sm")} {
        width: 100%;
        max-height: 100%;
      }

      ${({ theme }) => theme.breakpoints.up("sm")} {
        width: calc(100% - 20px);
        max-height: calc(100% - 20px);
        border-radius: ${({ theme }) => theme.borderRadius}px;
      }
    }

    &__header {
      ${({ theme }) => theme.breakpoints.down("lg")} {
        padding: 16px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        padding: 48px;
      }
    }

    &__btn-close {
      border: none;
      float: right;
      width: 44px;
      height: 44px;
      padding: 0;
      background-color: transparent;
      position: relative;
      cursor: pointer;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        margin-top: -8px;
        margin-right: -8px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        margin-top: -30px;
        margin-right: -30px;
      }

      &::before,
      &::after {
        position: absolute;
        left: 50%;
        top: 25%;
        content: " ";
        height: 25px;
        width: 2px;
        background-color: #333;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }

    &__image-wrapper {
      ${({ theme }) => theme.breakpoints.down("lg")} {
        width: 60px;
        height: 60px;
        flex: 0 0 60px;
        margin-right: 16px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        width: 120px;
        height: 120px;
        flex: 0 0 120px;
        margin-right: 28px;
      }

      > * {
        width: 100%;
        height: 100%;
      }

      &,
      * {
        border-radius: 50%;
        overflow: hidden;
      }
    }

    &__title-wrapper {
      display: flex;
      align-items: center;
    }

    &__body {
      overflow: auto;

      ${({ theme }) => theme.breakpoints.down("lg")} {
        padding: 0 16px 16px;
      }

      ${({ theme }) => theme.breakpoints.up("lg")} {
        padding: 0 48px 56px;
      }
    }

    &__position {
      font-size: 16px;
      line-height: 20px;
      color: ${({ theme }) => theme.palette.primary.dark};
    }

    &__name {
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
      font-size: 24px;
      line-height: 26px;
    }
  }
`

export default TeacherModalWrapper
