import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Container from "components/Container"
import Link from "components/Link"
import Button from "components/Button"
import TestWrapper from "components/Test/TestWrapper"
import Content from "components/Content/Content"
import SuccessIcon from "components/icons/SuccessIcon"

const Test = ({ title, subtitle, buttonNextTitle, tasks, results }) => {
  const [window, setWindow] = useState(1)
  const [answerIndex, setAnswerIndex] = useState(-1)
  const [balls, setBalls] = useState(0)

  const _results = results.sort((a, b) => a.numberPoints - b.numberPoints)
  const [result, setResult] = useState(_results[0])

  useEffect(() => {
    if (window === tasks.length + 1) {
      const res = [..._results, { numberPoints: 9999 }].find(
        (resItem, i, array) =>
          balls >= array[i]?.numberPoints && balls < array[i + 1]?.numberPoints,
      )
      if (!res || !res.length) setResult(_results[0])
      if (!res?.length) setResult(res)
    }
  }, [_results, balls, tasks.length, window])

  const handleNextWindow = () => {
    setAnswerIndex(-1)
    setWindow(window + 1)
    if (tasks[window - 1].correctAnswerNumber !== -1) setBalls(balls + answerIndex + 1)
  }

  return (
    <TestWrapper id="test">
      <Container>
        <div className="content-wrapper">
          <h1 className="title">{title}</h1>
          {window !== tasks.length + 1 && (
            <>
              <div className="test__task-header">
                <div className="test__task-caption">
                  {subtitle} {window} / {tasks.length}
                </div>
                <Content
                  className="test__task-title"
                  html={tasks[window - 1].task.childMarkdownRemark.html}
                />
              </div>
              <div className="test__test-answers">
                {tasks[window - 1].answers.text.split("\n").map((answer, index) => {
                  if (!answer) return null
                  return (
                    <div key={answer}>
                      <button
                        onClick={() => setAnswerIndex(index)}
                        className={`test__test-answer${index === answerIndex ? " active" : ""}`}
                        type="button"
                      >
                        {answer}
                      </button>
                    </div>
                  )
                })}
              </div>
              <Button
                disabled={answerIndex === -1}
                onClick={handleNextWindow}
                className="test__button next"
                variant="contained"
              >
                {buttonNextTitle}
              </Button>
            </>
          )}
          {window === tasks.length + 1 && (
            <div className="test__result-main">
              <SuccessIcon className="test__result-icon" />
              <div className="test__result-content-wrap">
                <Content
                  className="test__result-content"
                  html={result.content.childMarkdownRemark.html}
                />
              </div>
              {result?.buttonLink && result?.buttonTitle && (
                <div className="test__result-link-wrapper">
                  <Link className="test__result-link" to={result.buttonLink}>
                    {result.buttonTitle}
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
      </Container>
    </TestWrapper>
  )
}

Test.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  buttonNextTitle: PropTypes.string.isRequired,
  tasks: PropTypes.array.isRequired,
  results: PropTypes.array.isRequired,
}

export default Test
