import React from "react"
import PropTypes from "prop-types"
import Layout from "components/Layout"
import Seo from "components/Seo"
import * as Sections from "containers/sections"

import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "swiper/css/autoplay"

const Template = ({ pageContext: { bgGray, baseUrl, seo, sections }, location }) => {
  React.useLayoutEffect(() => {
    if (location?.hash) {
      const top = document.querySelector(location?.hash)?.offsetTop
      if (top) window.scrollTo({ top, behavior: "smooth" })
    }
  }, [location])

  return (
    <Layout lang={seo.lang} bgGray={bgGray}>
      <Seo {...seo} />
      {sections.map(({ id, component, ...props }) => {
        const Component = Sections[component]
        if (!Component) return null
        return <Component key={id} baseUrl={baseUrl} {...props} />
      })}
    </Layout>
  )
}

Template.propTypes = {
  pageContext: PropTypes.exact({
    bgGray: PropTypes.bool,
    baseUrl: PropTypes.string,
    seo: PropTypes.object,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        component: PropTypes.string,
      }),
    ),
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export default Template
